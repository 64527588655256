<div class="row">
  <div class="card_info_transactions_bordered flex-grow-1">
    <div class="card_head_trans"><img src="./assets/images/icons/outgoing-icon.svg" alt="" class="stats">
      <h4> Outgoing </h4>
    </div>
    <div class="card_inner_trans">
      <div class="item_stats_trans">
        <h4 class="title">Already Paid</h4>
        <div class="contaner_price purple">
          <strong>{{walletDetails.outgoing.amountAlreadyReceived | number}}</strong>
          {{defaultCurrency.currency.symbol}}
        </div>
      </div>
      <div class="item_stats_trans">
        <h4 class="title">To be Paid</h4>
        <div class="contaner_price">
          <strong>{{walletDetails.outgoing.amount | number}}</strong>
          {{defaultCurrency.currency.symbol}}
        </div>
      </div>
    </div>
  </div>
  <div class="card_inner_trans flex-column px-3 align-items-start">
    <div class="item_stats_trans m-0">
      <h4 class="title">Total Customers</h4>
      <div class="contaner_price">
        <strong>{{walletDetails.outgoing.totalCustomers}}</strong>
      </div>
    </div>
    <div class="item_stats_trans m-0">
      <h4 class="title">Total Invoices</h4>
      <div class="contaner_price">
        <strong>{{walletDetails.outgoing.totalInvoices}}</strong>
      </div>
    </div>
  </div>
</div>
