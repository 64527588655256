<ng-container *ngIf="walletDetails && walletDetails.recentWalletTransactionDate; else walletEmpty">
  <div class="row">
    <div class="col-7">
      <div class="card_head_trans">
        <svg width="40" height="38" viewBox="0 0 40 38">
          <path id="wallet-icon"
            d="M.007,32.622V4.44a4.092,4.092,0,0,1,0-.477A3.981,3.981,0,0,1,4.005,0H37.042V2.643H4.005A1.327,1.327,0,0,0,2.673,3.727V4.2A1.332,1.332,0,0,0,4.005,5.286H37.334v9.249H40V27.749H37.334v8.93A1.327,1.327,0,0,1,36,38H5.468A5.441,5.441,0,0,1,.007,32.622Zm2.666,0a2.8,2.8,0,0,0,2.838,2.734H34.668V27.749H25.336a6.607,6.607,0,1,1,0-13.214h9.332V7.93H4.005a4.173,4.173,0,0,1-1.332-.161Zm18.665-11.48a3.982,3.982,0,0,0,4,3.966h12v-7.93h-12A3.981,3.981,0,0,0,21.338,21.142Zm3.345,1a2,2,0,1,1,2,1.981A1.991,1.991,0,0,1,24.683,22.144Z"
            fill="#dbe0e5" />
        </svg>
        <div class="item_stats_trans ml-2">
          <h4 class="contaner_price purple ml-0 text-theme">
            {{walletDetails.walletBalance | number}}
            <small>{{defaultCurrency.currency.symbol}}</small>
          </h4>
          <label class="text-theme">Balance in Wallet</label>
        </div>
      </div>
    </div>
    <div class="col-5">
      <div class="card_inner_trans">
        <div class="item_stats_trans m-0">
          <h4 class="title">Recent transaction on:</h4>
          <div class="contaner_price">
            <small class="fontSemiBold">
              {{walletDetails.recentWalletTransactionDate | timeDiff}}
            </small>
          </div>
        </div>
      </div>
    </div>
    <a class="view-transactions" (click)="showTransactions()">View Transactions</a>
  </div>
</ng-container>

<ng-template #walletEmpty>
  <app-empty-state custClass="py-0" [desc]='"The wallet is empty, and no transactions have occurred."'
    [img]="'./assets/images/icons/wallet-icon.svg'"></app-empty-state>
</ng-template>

<app-sidenav-over *ngIf="openSideNavTrans && walletDetails" [open]="openSideNavTrans"
  (closeDetails)="closeSidenavTrans($event)">
  <app-transactions [isProfile]="isOurProfile" [walletId]="walletDetails.walletId" [entityId]="entityId"
    [entityType]="entityType" (closeDetails)="closeSidenavTrans($event)">
  </app-transactions>
</app-sidenav-over>
