import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConcurelService } from 'src/app/services/concurel.service';

@Component({
  selector: 'app-concurel-login',
  templateUrl: './concurel-login.component.html'
})
export class ConcurelLoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  hidePassword = true;
  errorMsg = null;
  formSubmitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private concurelService: ConcurelService) { }

  ngOnInit(): void {

    this.loginForm = this.formBuilder.group({
      username: ['', [
        Validators.required,
        Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9._@-]+$')
      ]],
      password: ['', Validators.required],
    });
  }

  login() {
    this.formSubmitted = true;
    if (this.loginForm.valid) {
      this.loading = true;
      const model = {
        username: this.loginForm.controls.username.value,
        password: this.loginForm.controls.password.value
      };
      this.concurelService.loginConcurel(model).subscribe(
        (data) => {
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          const err = JSON.parse(error.detail);
          this.errorMsg = err.Result.ErrorMessages;
         });
    }
  }
  hasError(controlName: string, errorName: string) {
    return this.loginForm.controls[controlName].hasError(errorName);
  }

}
