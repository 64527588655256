<div *ngIf='dataLoading' class="dataLoading">
  <mat-spinner class="m-auto" diameter="50"></mat-spinner>
</div>

<ng-container *ngIf='!dataLoading'>
  <div class="sidenav-user-toolbar">
    <h4 *ngIf="userType != 'PATHOLOGIST_REQUEST'"> User Details </h4>
    <h4 *ngIf="userType == 'PATHOLOGIST_REQUEST'"> Request Details </h4>
    <div class="button-actions-toolbar" *ngIf="userType !== 'PATHOLOGIST_REQUEST'">
      <!-- (module == 'PATHOLOGIST_USER' && isModuleAdmin) ||  -->
      <button mat-icon-button [matTooltip]="'delete'" (click)="deleteRecord()" [disabled]="actionsLoader"
        [hidden]="(hideActionsFromUsersNotSuerAdmin && user.userStatus !== 'NOT_VERIFIED')"
        [class.loading]='actionsLoader'>
        <svg width="16" height="20.002" viewBox="0 0 16 20.002">
          <path id="delete-icon"
            d="M4422.4,17833.639a1.853,1.853,0,0,1-1.844-1.785l-.51-10.822a.2.2,0,0,1,.192-.207H4433.9a.194.194,0,0,1,.192.207l-.505,10.822a1.859,1.859,0,0,1-1.844,1.785Zm7.134-11.252v8.129a.616.616,0,1,0,1.231,0v-8.129a.616.616,0,1,0-1.231,0Zm-3.079,0v8.129a.615.615,0,1,0,1.23,0v-8.129a.615.615,0,1,0-1.23,0Zm-3.075,0v8.129a.615.615,0,1,0,1.23,0v-8.129a.615.615,0,1,0-1.23,0Zm-3.692-2.811a.621.621,0,0,1-.618-.627v-1.248a1.551,1.551,0,0,1,1.54-1.562h3.383v-.627a1.864,1.864,0,0,1,1.849-1.875h2.461a1.86,1.86,0,0,1,1.844,1.875v.627h3.383a1.551,1.551,0,0,1,1.54,1.563v1.248a.617.617,0,0,1-.613.627Zm5.536-4.064v.627h3.692v-.627a.616.616,0,0,0-.613-.621h-2.461A.619.619,0,0,0,4425.223,17815.512Z"
            transform="translate(-4419.069 -17813.637)" />
        </svg>
      </button>
      <button mat-icon-button [matTooltip]="'Remove Pathologist'" (click)="deleteRecord(true)"
        [disabled]="actionsLoader" *ngIf="hideActionsFromUsersNotSuerAdmin && user.userStatus !== 'NOT_VERIFIED'"
        [class.loading]='actionsLoader'>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <path id="remove-icon"
            d="M-19723.285-716.211h-6.891a.826.826,0,0,1-.824-.829,6.342,6.342,0,0,1,6.311-6.36,6.286,6.286,0,0,1,1.3.137,5.744,5.744,0,0,1,4.627-2.348,5.791,5.791,0,0,1,5.762,5.806,5.792,5.792,0,0,1-5.762,5.806A5.739,5.739,0,0,1-19723.285-716.211Zm.406-3.594a4.136,4.136,0,0,0,4.117,4.148,4.136,4.136,0,0,0,4.113-4.148,4.135,4.135,0,0,0-4.113-4.147A4.136,4.136,0,0,0-19722.879-719.805Zm4.7,1.76-.58-.587-.584.587a.816.816,0,0,1-.582.243.812.812,0,0,1-.58-.243.829.829,0,0,1,0-1.173l.58-.587-.58-.586a.829.829,0,0,1,0-1.173.816.816,0,0,1,1.162,0l.584.587.58-.587a.819.819,0,0,1,1.164,0,.829.829,0,0,1,0,1.173l-.58.586.58.587a.829.829,0,0,1,0,1.173.822.822,0,0,1-.58.243A.824.824,0,0,1-19718.182-718.046Zm-9.953-10.483a3.461,3.461,0,0,1,3.445-3.47,3.461,3.461,0,0,1,3.443,3.47,3.461,3.461,0,0,1-3.443,3.47A3.461,3.461,0,0,1-19728.135-728.529Z"
            transform="translate(19731 731.999)" fill="#616e7c" />
        </svg>
      </button>
      <button mat-icon-button [matTooltip]="'Send Reset Password Link'" [hidden]="user.userStatus != 'ACTIVE'"
        (click)="ResendResetPasswordMail()" [disabled]="actionsLoader" [class.loading]='actionsLoader'>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18.997" viewBox="0 0 20 18.997">
          <path id="send-reset-password-link-icon"
            d="M2758,14841.5a9.475,9.475,0,0,1,18.632-2.452l.151-1.029a.631.631,0,0,1,1.172.47l-1.037,3.247a.632.632,0,0,1-.46.387.745.745,0,0,1-.128.012.611.611,0,0,1-.445-.187l-2.092-2.665a.632.632,0,0,1,.893-.895l.83,1.4a8.224,8.224,0,1,0-2.224,7.533.633.633,0,1,1,.893.9,9.478,9.478,0,0,1-16.186-6.72Zm6.747,4.782a.887.887,0,0,1-.889-.884v-5.493a.22.22,0,0,1,.222-.221h.667v-.879a3.114,3.114,0,0,1,6.227,0v.879h.667a.22.22,0,0,1,.223.221v5.493a.887.887,0,0,1-.89.884Zm2.224-3.958a.853.853,0,0,0,.366.7l-.143,1.248a.224.224,0,0,0,.057.172.228.228,0,0,0,.166.074h.89a.228.228,0,0,0,.166-.074.236.236,0,0,0,.056-.172l-.143-1.248a.853.853,0,0,0,.366-.7.89.89,0,0,0-1.779,0Zm-.889-3.519v.879h3.558v-.879a1.779,1.779,0,0,0-3.558,0Z"
            transform="translate(-2758.002 -14832.002)" />
        </svg>
      </button>
      <button mat-icon-button [matTooltip]="'edit'" (click)="openEditUser()" [disabled]="actionsLoader"
        [class.loading]='actionsLoader'>
        <svg width="16" height="16.004" viewBox="0 0 16 16.004">
          <path id="edit-icon"
            d="M5.82,14l-3.63-3.63,8.78-8.78,3.63,3.63ZM1.851,11.011l3.33,3.33L.188,16Zm13.865-6.9-.63.63L11.451,1.1l.63-.63a1.624,1.624,0,0,1,2.3,0l1.338,1.338a1.633,1.633,0,0,1,0,2.3Zm0,0"
            transform="translate(-0.188 0.001)" />
        </svg>
      </button>
      <button mat-raised-button color="primary" class="button_with_icon" (click)="deactivateRecord()"
        [hidden]="hideActionsFromUsersNotSuerAdmin" [disabled]="actionsLoader" [class.loading]='actionsLoader'
        *ngIf="user.userStatus === 'ACTIVE'">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12">
          <path id="deactivate-icon"
            d="M910.643,466.955h-8a6,6,0,0,0,0,12h8a6,6,0,0,0,0-12Zm-8.093,8.25a.75.75,0,0,1-1.5,0v-4.5a.75.75,0,0,1,1.5,0Zm8.093,2.083a4.333,4.333,0,1,1,4.332-4.333A4.332,4.332,0,0,1,910.643,477.288Z"
            transform="translate(-896.643 -466.955)" fill="#fff" opacity="0.85" />
        </svg>
        <span class="value">Deactivate</span>
      </button>
      <button mat-raised-button color="primary" class="button_with_icon" (click)="activateUser()"
        [hidden]="hideActionsFromUsersNotSuerAdmin" [disabled]="actionsLoader" [class.loading]='actionsLoader'
        *ngIf="user.userStatus === 'INACTIVE'">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12">
          <path id="deactivate-icon"
            d="M910.643,466.955h-8a6,6,0,0,0,0,12h8a6,6,0,0,0,0-12Zm-8.093,8.25a.75.75,0,0,1-1.5,0v-4.5a.75.75,0,0,1,1.5,0Zm8.093,2.083a4.333,4.333,0,1,1,4.332-4.333A4.332,4.332,0,0,1,910.643,477.288Z"
            transform="translate(-896.643 -466.955)" fill="#fff" opacity="0.85" />
        </svg>
        <span class="value">Activate</span>
      </button>
      <button mat-raised-button color="primary" class="button_with_icon" [hidden]="user.userStatus != 'NOT_VERIFIED'"
        [disabled]="actionsLoader" [class.loading]='actionsLoader' (click)="resendActivationMail()">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <path id="resend-verification-mail-icon"
            d="M3.839,17.87A9.574,9.574,0,0,1-.392,7.228,9.7,9.7,0,0,1,3,2.043,9.877,9.877,0,0,1,7.624.009V-1.528A.556.556,0,0,1,8.465-2L11.978.456a.58.58,0,0,1,0,.967L8.465,3.872A.549.549,0,0,1,7.624,3.4L7.6,2.068A7.884,7.884,0,0,0,4.036,3.457,8.236,8.236,0,0,0,.919,7.625,8.458,8.458,0,0,0,4.209,17.3a.349.349,0,0,1,.075.472.333.333,0,0,1-.275.151A.3.3,0,0,1,3.839,17.87ZM4.5,16.694A1.131,1.131,0,0,1,3.443,15.5V7.822l7.58,3.369a1,1,0,0,0,.341.085.994.994,0,0,0,.341-.085l7.58-3.369V15.5a1.168,1.168,0,0,1-1.137,1.194ZM3.48,6.926V6.414a1.162,1.162,0,0,1,1.1-1.194H18.223a1.131,1.131,0,0,1,1.061,1.194v.512l-7.921,3.5Z"
            transform="translate(0.715 2.078)" />
        </svg>
        <span class="value">Resend Verification Mail</span>
      </button>
    </div>
  </div>
  <div class="sidenav-user-container">
    <div class="top_profile">
      <div class="overlay-profile"></div>
      <div class="user_info">
        <div class="user_info_avatar">
          <img *ngIf="user.userImage" [src]="user.userImage" (error)='user.userImage = ""'>
          <span *ngIf="!user.userImage">{{ user.fullName | shortName }}</span>
          <label class="icon-upload d-none" for="uploadAvatar">
            <input type='file' class="d-none" id="uploadAvatar" accept="image/jpeg, image/pjpeg, image/png"
              (change)="uploadAvatar($event)">
            <img src="assets/images/icons/insert-picture-icon.svg" alt="">
          </label>
        </div>
        <h4>{{user.fullName}}</h4>
      </div>
    </div>
    <div class="profile_detail">
      <span [ngSwitch]="user.userStatus" *ngIf="module != 'PATHOLOGIST_REQUEST'">
        <span class="badge-status warning" *ngSwitchCase="'NOT_VERIFIED'">
          Not Verified Yet
        </span>
        <span class="badge-status success" *ngSwitchCase="'ACTIVE'">
          Active
        </span>
        <span class="badge-status" *ngSwitchCase="'INACTIVE'">
          InActive
        </span>
        <span class="badge-status warning" *ngSwitchDefault>
          Not Verified Yet
        </span>
      </span>
      <span [ngSwitch]="user.acceptenceStatus" *ngIf="module == 'PATHOLOGIST_REQUEST'">
        <span class="badge-status warning" *ngSwitchCase="'PENDING'">
          New Request
        </span>
        <span class="badge-status success" *ngSwitchCase="'ACCEPTED'">
          Accepted
        </span>
        <span class="badge-status danger" *ngSwitchCase="'REJECTED'">
          Rejected
        </span>
      </span>
      <ul class="list-items">
        <ng-container *ngIf="module !== 'SCANNING_CENTER_USER' && module !== 'LAB_USER'; else labUser">
          <li *ngIf="user.userStatus === 'NOT_VERIFIED'">
            <span class="title">
              Created on
            </span>
            <div class="desc">
              {{user.creationDate| date:'d MMM, y, h:mm a'}}
            </div>
          </li>
          <li *ngIf="user.userStatus === 'ACTIVE'">
            <span class="title">
              Member Since
            </span>
            <div class="desc">
              {{user.memberSince| date:'d MMM, y, h:mm a'}}
            </div>
          </li>
          <li *ngIf="user.userStatus === 'INACTIVE'">
            <span class="title">
              Deactivated on
            </span>
            <div class="desc">
              {{user.deactivatedOn| date:'d MMM, y, h:mm a'}}
            </div>
          </li>
        </ng-container>
        <ng-template #labUser>
          <ng-container *ngIf="module === 'LAB_USER'; else otherDate">
            <li *ngIf="user.userStatus === 'ACTIVE'">
              <span class="title">
                Member Since
              </span>
              <div class="desc">
                {{user.memberSince| date:'d MMM, y, h:mm a'}}
              </div>
            </li>
          </ng-container>
        </ng-template>

        <ng-template #otherDate>
          <li *ngIf="user.userStatus === 'NOT_VERIFIED'">
            <span class="title">
              Created on
            </span>
            <div class="desc">
              {{user.creationDate| date:'d MMM, y, h:mm a'}}
            </div>
          </li>
        </ng-template>


        <li *ngIf="user.userType === 'LAB_USER' || user.userType === 'Lab'">
          <span class="title">
            Lab
          </span>
          <div class="desc">
            <div class="item_avatar_inner small">
              <img *ngIf="user.labLogo" [src]="user.labLogo" (error)='user.labLogo = ""'>
              <span *ngIf="!user.labLogo">
                {{ user.lab.name | shortName }}
              </span>
              <div class="item_avatar_content">
                <h4 class="m-0">{{ user.lab.name }}</h4>
              </div>
            </div>
          </div>
        </li>
        <!-- <li *ngIf="user.userType === 'VIRTUAL_LAB_USER'">
          <span class="title">
            Lab
          </span>
          <div class="desc">
            <div class="item_avatar_inner small">
              <img *ngIf="user.labLogo" [src]="user.labLogo" (error)='user.labLogo = ""'>
              <span *ngIf="!user.labLogo">
                {{ user.virtualLab.name| shortName }}
              </span>
              <div class="item_avatar_content">
                <h4 class="m-0">{{ user.virtualLab.name }}</h4>
              </div>
            </div>
          </div>
        </li> -->
        <li *ngIf="user.userType === 'SCANNING_CENTER_USER'">
          <span class="title">
            Scanning Center
          </span>
          <div class="desc">
            <div class="item_avatar_inner small">
              <img *ngIf="user.labLogo" [src]="user.labLogo" (error)='user.labLogo = ""'>
              <span *ngIf="!user.labLogo">
                {{ user.scanningCenter.name| shortName }}
              </span>
              <div class="item_avatar_content">
                <h4 class="m-0">{{ user.scanningCenter.name }}</h4>
              </div>
            </div>
          </div>
        </li>
        <li *ngIf="(user.acceptedBy?.name || user.declinedBy?.name) && userType == 'PATHOLOGIST_REQUEST'">
          <span class="title">
            <ng-container *ngIf="user.acceptedBy?.name">
              Accepted by
            </ng-container>
            <ng-container *ngIf="user.declinedBy?.name">
              Declined by
            </ng-container>
          </span>
          <div class="desc d-flex align-items-center">
            <div class="img-circle mr-3">
              <div class="image m-0">
                <img *ngIf="user.acceptedBy?.image" [src]="user.acceptedBy?.image" (error)='user.acceptedBy.image = ""'>
                <img *ngIf="user.declinedBy?.image" [src]="user.declinedBy?.image" (error)='user.declinedBy.image = ""'>
                <span>
                  <ng-container *ngIf="!user.acceptedBy?.image">
                    {{user.acceptedBy?.name | shortName }}
                  </ng-container>
                  <ng-container *ngIf="!user.declinedBy?.image">
                    {{user.declinedBy?.name | shortName}}
                  </ng-container>
                </span>
              </div>
            </div>
            <div *ngIf="user.acceptedBy?.name">
              {{user.acceptedBy?.name}} <span class="title" *ngIf="user.acceptedBy.isYou">(you)</span>
            </div>
            <div *ngIf="user.declinedBy?.name">
              {{user.declinedBy?.name}} <span class="title" *ngIf="user.declinedBy.isYou">(you)</span>
            </div>
          </div>
        </li>
        <li *ngIf="user.addedByRole && userType !== 'PATHOLOGIST_REQUEST'">
          <span class="title">
            Added by
          </span>
          <div class="desc">
            {{user.addedByRole}}
          </div>
        </li>
        <li *ngIf="user.createdByUser?.name">
          <span class="title">
            Added by
          </span>
          <div class="desc d-flex align-items-center">
            <div class="img-circle mr-3">
              <div class="image m-0">
                <img *ngIf="user.createdByUser?.image" [src]="user.createdByUser?.image"
                  (error)='user.createdByUser.image = ""'>
                <span *ngIf="!user.createdByUser?.image">
                  {{user.createdByUser?.name | shortName }}
                </span>
              </div>
            </div>
            <div>
              {{user.createdByUser?.name}} <span class="title" *ngIf="user.createdByUser.isYou">(you)</span>
            </div>
          </div>
        </li>
        <li *ngIf="user.identityNumber">
          <span class="title">
            <ng-container *ngIf="user.identificationType?.code != 'OT'">{{user.identificationType?.name}}</ng-container>
            <ng-container *ngIf="user.identificationType?.code == 'OT'">{{user.otherIdentityType}}</ng-container>


          </span>
          <div class="desc">
            {{user.identityNumber}}
          </div>
        </li>
        <li>
          <span class="title">
            Mobile No.
          </span>
          <div class="desc">
            {{user.contactNumberExtension + ' ' + user.contactNumber}}
            <ng-container *ngIf="user.isVerfiedMobile">
              <span class="badge_status_progress success">
                (Verified)
              </span>
            </ng-container>
            <ng-container *ngIf="!user.isVerfiedMobile">
              <span class="badge_status_progress warning">
                (Not Verified)
              </span>
            </ng-container>
          </div>
        </li>
        <li>
          <span class="title">
            Email
          </span>
          <div class="desc">
            <a [href]="'mailto:'+user.email">{{user.email}}</a>
          </div>
        </li>
        <li *ngIf="user.canShareToConCural">
          <div class="conatnier_actions">
            <span class="badge_status_progress dark-orange">
              Can Share to Concural
            </span>
          </div>
        </li>

        <li *ngIf="user.lab?.name && (user.userType === 'HOSPITAL_USER' || user.userType === 'CLINIC_USER')">
          <span class="title">
            <ng-container *ngIf="user.userType === 'HOSPITAL_USER'">Hospital</ng-container>
            <ng-container *ngIf="user.userType === 'CLINIC_USER'">Clinic</ng-container>


          </span>
          <div class="desc">
            <div class="item_avatar_inner small">
              <img *ngIf="user.labLogo" [src]="user.labLogo" (error)='user.labLogo = ""'>
              <span *ngIf="!user.labLogo">
                {{ user.lab.name| shortName }}
              </span>
              <div class="item_avatar_content">
                <h4 class="m-0">{{ user.lab.name }}</h4>
              </div>
            </div>
          </div>
        </li>
        <li>
          <span class="title">
            Address
          </span>
          <div class="desc">
            <p>{{user.address}}</p>
            <p>{{user.city.name}}, {{user.region.name}}</p>
            <p>{{user.country.name}}</p>
          </div>
        </li>
        <li
          *ngIf="(!moduleView || moduleView !== 'allPathologists') && (module == 'Lab' || module == 'LAB_USER' || module == 'VIRTUAL_LAB_USER' || module == 'PATHOLOGIST_USER') && (checkRole('LabTechnician') || checkRole('VLTechnician') || checkRole('Pathologist')) && (hasPermission('SuperAdmin') || hasPermission('LabAdmin') || hasPermission('VLAdmin'))">
          <div class="desc">
            <ul class="list_schedules">
              <li class="item_schedule justify-content-between">
                <div class="d-flex align-items-center">
                  <img src="assets/images/icons/performance-indicator-illustration.svg" alt="datetime">
                  <div class="item_schedule_inner">
                    <h4>Statistics of Performance Indicator</h4>
                    <span>
                      Track and improve productivity and other important metrics
                    </span>
                  </div>
                </div>
                <div class="item_right">
                  <button (click)="performanceStatistics()" mat-raised-button
                    class="small-btn button_with_icon icon_right" color="primary">
                    Generate
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                      <path id="external-icon"
                        d="M10611.556-13410a1.556,1.556,0,0,1-1.557-1.554v-10.89a1.558,1.558,0,0,1,1.557-1.557h3.889l1.555,1.557h-5.443v10.89h10.889V-13417l1.555,1.557v3.889a1.555,1.555,0,0,1-1.555,1.554Zm3.339-5.992,5.445-5.444-2.562-2.563H10624v6.224l-2.562-2.562-5.443,5.445Z"
                        transform="translate(-10609.999 13424.002)" fill="#fff" />
                    </svg>
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <ul class="list-items border-0 pb-0">
        <li>
          <span class="title">
            Role
          </span>
          <div class="desc">
            <ul>
              <li class="tag-item" *ngFor="let role of user.rolesNames">
                {{role}}
              </li>
            </ul>
          </div>
        </li>
        <li [hidden]="!checkRole('Pathologist') && !checkRole('VLPathologist')">
          <span class="title">
            Subspecialty
          </span>
          <div class="desc">
            <ul class="list_style_square">
              <li class="item_style_square" *ngFor="let subSpeciality of user.subSpeciality">
                <i class="material-icons">
                  check_circle_outline
                </i>
                {{subSpeciality.subSpeciality}}
              </li>
            </ul>
          </div>
        </li>
        <li *ngIf="user.reviwer">
          <span class="title">
            Reviewer
          </span>
          <div class="desc">
            <div class="item_avatar_inner">
              <img *ngIf="user.reviwer.logo" [src]="user.reviwer.logo" (error)='user.reviwer.logo = ""'>
              <span *ngIf="!user.reviwer.logo">
                {{ user.reviwer.reviewerName | shortName }}
              </span>
              <div class="item_avatar_content">
                <h4>
                  {{user.reviwer.reviewerName}}
                </h4>
                <ng-container *ngFor="let item of user.reviwer.reviewerRolesName; index as i">
                  <span>{{(i !== 0 ? ' ,' : '') + item}}</span>
                </ng-container>
              </div>
            </div>
          </div>
        </li>
        <li *ngIf="user.canSendScondOpinion">
          <div class="desc">
            <ul class="list_style_square">
              <li class="item_style_square">
                <i class="material-icons">
                  check_circle_outline
                </i>
                Can request a second opinion from another Pathologist
              </li>
            </ul>
          </div>
        </li>
        <li [hidden]="!checkRole('Pathologist') && !checkRole('VLPathologist')">
          <span class="title">
            Availability
          </span>
          <div class="desc">
            <ul class="list_schedules">
              <li class="item_schedule" *ngFor="let item of user.availability">
                <img src="assets/images/icons/datetime-icon.svg" alt="datetime">
                <div class="item_schedule_inner">
                  <h4>{{item.availableDay}}</h4>
                  <span>
                    {{'0000-00-00T' + item.availableTimeFrom | date:'hh:mm aa'}}
                  </span> :
                  <span>
                    {{'0000-00-00T' + item.availableTimeTo | date:'hh:mm aa'}}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </li>

        <li [hidden]="!checkRole('Pathologist') && !checkRole('VLPathologist')">
          <div *ngIf="user.lab?.length">
            <span class="title">
              Work at
            </span>
            <div class="desc">
              <div class="details-section-container" *ngFor="let item of user.lab">
                <div class="work-at-details">
                  <div class="img-circle">
                    <div class="image m-0">
                      <img *ngIf="item.labLogo" [src]="item.labLogo" (error)='item.labLogo = ""'>
                      <span *ngIf="!item.labLogo">{{ item.labName | shortName }}</span>
                    </div>
                  </div>
                  <div>
                    <div class="lab-title">{{item.labName}}</div>
                    <div class="joined-date">Joined on: {{item.joinedAt | date:'d MMM, y, h:mm a'}}</div>
                    <ng-container *ngIf="item.hasReviewer;else noReviwer">
                      <div class="reviewer-details" *ngIf="item.reviwer?.reviewerName">
                        <div class="reviewer-title">Reviewer</div>
                        <div class="img-circle">
                          <div class="image m-0">
                            <img *ngIf="item.reviwer?.logo" [src]="item.reviwer?.logo" (error)='item.reviwer.logo = ""'>
                            <span *ngIf="!item.reviwer?.logo">{{ item.reviwer?.reviewerName | shortName }}</span>
                          </div>
                        </div>
                        <div class="reviewer-name">{{item.reviwer?.reviewerName}}</div>
                      </div>
                      <div class="reviewer-details" *ngIf="item.reviwer?.reviewerName">
                        <div class="reviewer-title">Reviewer Role: </div>
                        <div class="reviewer-name ml-2">{{item.reviwer?.reviewerRole}}</div>
                      </div>
                    </ng-container>

                    <ng-template #noReviwer>
                      Has no reviewer
                    </ng-template>


                    <div class="desc" *ngIf="item.canSendScondOpinion">
                      <ul class="list_style_square">
                        <li class="item_style_square">
                          <i class="material-icons">
                            check_circle_outline
                          </i>
                          Can request a second opinion from another Pathologist
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <a *ngIf="hasPermission('SuperAdmin')"
                  [routerLink]=" item.labType == 'LAB' ? ['/admin/labs/' , item.labId ] : ['/admin/vlProfile']"
                  class="view-details-btn" target="_blank">
                  View
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="external-icon"
                      d="M10611.556-13410a1.556,1.556,0,0,1-1.557-1.554v-10.89a1.558,1.558,0,0,1,1.557-1.557h3.889l1.555,1.557h-5.443v10.89h10.889V-13417l1.555,1.557v3.889a1.555,1.555,0,0,1-1.555,1.554Zm3.339-5.992,5.445-5.444-2.562-2.563H10624v6.224l-2.562-2.562-5.443,5.445Z"
                      transform="translate(-10609.999 13424.002)" fill="#5c316c" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </li>
        <li [hidden]="!checkRole('Pathologist') && !checkRole('VLPathologist')">
          <div *ngIf="user.slas?.length">
            <span class="title">
              Service Level Agreement(s)
            </span>
            <div class="desc">
              <div class="details-section-container" *ngFor="let item of user.slas">
                <div>
                  <div class="work-at-details sla">
                    <div class="img-circle">
                      <div class="image m-0">
                        <img *ngIf="item.entityImage" [src]="item.entityImage" (error)='item.entityImage = ""'>
                        <span *ngIf="!item.entityImage">{{ item.entityName | shortName }}</span>
                      </div>
                    </div>
                    <div class="">
                      <div class="lab-title">{{item.entityName}}</div>
                    </div>
                  </div>
                  <div class="work-at-details sla mt-3">
                    <app-sla-status [status]="item.slaStatus"></app-sla-status>
                    <ng-container *ngIf="item.slaStatus === 'APPROVED'">
                      <div class="joined-date mr-2 ml-2"> <span>From: </span>
                        {{item.startDate | date:'d MMM, y, h:mm a'}}
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" class="mt-2">
                        <circle id="circle" cx="3" cy="3" r="3" fill="#cbd2d9" />
                      </svg>
                      <div class="joined-date ml-2"> <span>To: </span> {{item.endDate | date:'d MMM, y, h:mm a'}}</div>
                    </ng-container>

                  </div>
                </div>
                <a [routerLink]="['/admin/sla/pathologists/preview-sla/' , item.slaId ]" class="view-details-btn"
                  target="_blank">
                  View
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="external-icon"
                      d="M10611.556-13410a1.556,1.556,0,0,1-1.557-1.554v-10.89a1.558,1.558,0,0,1,1.557-1.557h3.889l1.555,1.557h-5.443v10.89h10.889V-13417l1.555,1.557v3.889a1.555,1.555,0,0,1-1.555,1.554Zm3.339-5.992,5.445-5.444-2.562-2.563H10624v6.224l-2.562-2.562-5.443,5.445Z"
                      transform="translate(-10609.999 13424.002)" fill="#5c316c" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </li>
        <li *ngIf="checkRole('ScanningCenterTechnician') || checkRole('ScanningCenterRepresentative')">
          <span class="title">
            Covered Areas
          </span>
          <div class="desc">
            <ul class="list_with_check">
              <li class="mb-4" *ngFor="let item of user.coverageAreas">
                <div class="icon">
                  <span class="material-icons">
                    check_circle_outline
                  </span>
                </div>
                <div class="content">
                  <h4>{{item.name}}</h4>
                  <p>
                    Cities
                  </p>
                  <div class="list_with_dots mt-0" *ngIf="item.childs && item.childs.length">
                    <ul>
                      <li *ngFor="let child of item.childs">
                        {{child.name}}
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li *ngIf="checkRole('VLTechnician') || checkRole('VLRepresentative')">
          <span class="title">
            Covered Areas
          </span>
          <div class="desc">
            <ul class="list_with_check multi_levels">
              <li *ngFor="let item of user.coverageAreas">
                <h3 class="subtitle-form">{{item.name}}</h3>
                <div class="level_inner mb-3" *ngFor="let node of item.childs">
                  <div class="icon">
                    <span class="material-icons">
                      check_circle_outline
                    </span>
                  </div>
                  <div class="content">
                    <h4>{{node.name}}</h4>
                    <p>
                      Cities
                    </p>
                    <div class="list_with_dots mt-0" *ngIf="node.childs && node.childs.length">
                      <ul>
                        <li *ngFor="let child of node.childs">
                          {{child.name}}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li *ngIf="user.rejectionReason">
          <span class="title">
            Decline Reason
          </span>
          <div class="desc">
            {{user.rejectionReason}}
          </div>
        </li>
      </ul>
    </div>
    <div class="profile_actions" *ngIf="userType == 'PATHOLOGIST_REQUEST' && user.acceptenceStatus == 'PENDING'">
      <div class="title">Waiting your Decision</div>
      <div class="actions-container">
        <button mat-raised-button class="mat-green mr-2" (click)="acceptRequest(user.id)" [disabled]="actionsLoader">
          Accept Request
        </button>
        <button mat-raised-button class="mat-warn" (click)="declineRequest(user.id)" [disabled]="actionsLoader">
          Decline Request
        </button>
      </div>
    </div>
  </div>
</ng-container>
