import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  record: {
    qrToken: string;
    generatedSlideReference: string;
  };
}

@Component({
  selector: 'app-print-qrcode',
  templateUrl: './print-qrcode.component.html'
})
export class PrintQrcodeComponent implements OnInit {
  loading = false;
  QrCode: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<PrintQrcodeComponent>,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.QrCode = decodeURIComponent(this.data.record.qrToken);
  }

  print(id) {
    const printContent = document.getElementById(id);
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(printContent.innerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }

}
