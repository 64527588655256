import { Component, Input, OnInit } from '@angular/core';
import { DefaultCurrency } from 'src/app/models/currency/DefaultCurrency';
import { ListHeaderData } from 'src/app/models/payments/list-header-data';
import { CurrencyService } from 'src/app/services/currency.service';

@Component({
  selector: 'app-entity-balance',
  templateUrl: './entity-balance.component.html'
})
export class EntityBalanceComponent implements OnInit {
  @Input() walletDetails: ListHeaderData = new ListHeaderData();
  @Input() isOurProfile: boolean;
  @Input() entityId: number;
  @Input() entityType: string;
  defaultCurrency: DefaultCurrency;
  openSideNavTrans: boolean;

  constructor(private currencyService: CurrencyService) {
    this.defaultCurrency = this.currencyService.defaultCurrencyValue;
  }

  ngOnInit() {
  }

  showTransactions() {
    this.openSideNavTrans = true;
  }

  closeSidenavTrans(e) {
    this.openSideNavTrans = false;
  }

}
