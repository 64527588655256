import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Details } from 'src/app/models/cases/Details';
import { CaseType } from 'src/app/models/cases/enums/CaseType';
import { ListData, PagedData } from 'src/app/models/slides/ListData';
import { ListFilter } from 'src/app/models/slides/ListFilter';
import { RolesEnum } from 'src/app/models/users/roles/enums/roles';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConcurelService } from 'src/app/services/concurel.service';
import { SlidesService } from 'src/app/services/slides.service';

export interface DialogData {
  caseId: number;
  case: Details;
  entityId: number;
  pathologistId: number;
  requestId: number;
  pathologistType: string;
  IsEndorsement: string;
  caseIdType: CaseType;
  isShare: boolean;
  notAuthenticated: boolean;
  conCuralUserName: string;
  conCuralPassword: string;
}

@Component({
  selector: 'app-viewer-selector',
  templateUrl: './viewer-selector.component.html',
})
export class ViewerSelectorComponent implements OnInit {
  selected = [];
  dataLoading = false;
  loading = false;
  // table
  pageNumber = 1;
  pageSize = 6;
  pageCount: number;
  recordsData: ListData = new ListData();
  filterModel: ListFilter = new ListFilter();
  slidesList: Array<PagedData> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ViewerSelectorComponent>,
    private auth: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private service: SlidesService,
    private concurelService: ConcurelService
  ) {}

  ngOnInit(): void {
    this.getAll(null);
  }

  selectedSlides(id) {
    const index = this.selected.findIndex((slideId) => slideId === id);
    if (index > -1) {
      this.selected.splice(index, 1);
    } else {
      const item = this.slidesList.find((user) => user.slideId === id);
      this.selected.push(item.slideId);
    }
  }

  getAll(event) {
    this.dataLoading = true;
    if (event) {
      this.pageNumber = 1;
      this.slidesList = [];
    }
    this.filterModel.IsMulti = true;
    this.filterModel.PageSize = this.pageSize;
    this.filterModel.PageNumber = this.pageNumber;
    this.filterModel.CaseId = this.data.entityId;
    this.filterModel.caseIdType = this.data.caseIdType;
    if (this.data.pathologistId) {
      this.filterModel.UserId = this.data.pathologistId;
      this.filterModel.UserType = this.data.pathologistType;
    }
    if (
      this.data.case &&
      this.data.case.sentBackDRequest &&
      ((this.hasPermission(
        `${RolesEnum.SuperAdmin},${RolesEnum.VLAdmin},${RolesEnum.VLTechnician}`
      ) &&
        !('userId' in this.activatedRoute.snapshot.queryParams)) ||
        this.hasPermission(
          `${RolesEnum.ScanningCenterAdmin},${RolesEnum.SCTechnician}`
        ))
    ) {
      this.filterModel.SentBackRequestId = this.data.case.sentBackDRequest.id;
    }
    this.service.getAll(this.filterModel, this.data.notAuthenticated).subscribe(
      (data) => {
        if (data) {
          this.recordsData = data as ListData;
          this.pageCount = Math.ceil(
            this.recordsData.casesCount / this.filterModel.PageSize
          );
          if (this.recordsData.pagedData && this.recordsData.pagedData.length) {
            this.slidesList = this.slidesList.concat(
              this.recordsData.pagedData
            );
          }
        }
        this.dataLoading = false;
      },
      (error) => {
        this.dataLoading = false;
      }
    );
  }

  getNextPage() {
    if (this.pageNumber <= this.pageCount - 1) {
      this.pageNumber += 1;
      this.getAll(false);
    }
  }

  isLocalStorageSaved(): boolean {
    const concurelUser = localStorage.getItem('concurelUser');
    return concurelUser !== null && concurelUser !== undefined;
  }

  loginConcurel() {
    this.loading = true;
    // if (
    //   (this.data.conCuralUserName ||
    //     this.data.conCuralPassword ||
    //     this.data.conCuralUserName == '' ||
    //     this.data.conCuralPassword == '') &&
    //   this.isLocalStorageSaved()
    // ) {
    //   localStorage.removeItem('concurelUser');
    // }
    if (this.data.conCuralUserName || this.data.conCuralPassword) {
      localStorage.removeItem('concurelUser');
    }
    if (
      this.data.conCuralUserName &&
      this.data.conCuralPassword &&
      !this.isLocalStorageSaved()
    ) {
      const model = {
        username: this.data.conCuralUserName,
        password: this.data.conCuralPassword,
        IsFromCaseDetails: true,
      };
      this.concurelService.loginConcurel(model).subscribe(
        (data) => {
          this.loading = false;
          this.dialogRef.close({ slideList: this.selected });
        },
        (error) => {
          this.loading = false;
          this.dialogRef.close({
            notAuthorized: true,
            slideList: this.selected,
          });
        }
      );
    } else {
      this.dialogRef.close({ notAuthorized: false, slideList: this.selected });
    }
  }

  hasPermission(permission: string) {
    return this.auth.hasPermission(permission);
  }

  isInArray(id: number) {
    return this.selected.some((slideId) => slideId === id);
  }
}
