<div class="row m-0 justify-content-center">
  <div class="col-md-6">
    <div class="card login">
      <div class="card-body text-center">
        <div class="card-top">
          <img src="assets/images/concurel_logo.svg" />
          <h2>Welcome back!</h2>
          <p>Login to your account...</p>
        </div>
        <div class="form-group alert alert-danger" *ngIf="errorMsg">{{errorMsg}}</div>

        <form [formGroup]="loginForm" (ngSubmit)='login()' autocomplete="off">
          <mat-form-field class="form-group" [appearance]="'fill'">
            <mat-label>Username</mat-label>
            <input matInput autocomplete="off" formControlName="username" minlength="6" maxlength="20">
            <mat-error *ngIf="hasError('username', 'required')">{{ 'forms.Required' | translate }}</mat-error>
            <mat-error *ngIf="hasError('username', 'minlength') || hasError('username', 'pattern')">
              Invalid Username
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-group" [appearance]="'fill'">
            <mat-label>{{ 'forms.Password' | translate }}</mat-label>
            <input matInput autocomplete="off" formControlName="password" [type]="hidePassword ? 'password' : 'text'"
              maxlength="50" minlength="8">
            <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword"
              [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
              <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="hasError('password', 'required')">{{ 'forms.Required' | translate }}</mat-error>
            <mat-error *ngIf="hasError('password', 'minlength')">
              Invalid Password
            </mat-error>
          </mat-form-field>

          <div class="btn-container">
            <button mat-raised-button color="primary" class="w-100" [class.loading]='loading' [disabled]='loading'>
              {{'auth.Login_in' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
