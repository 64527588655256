<div class="card_info_profile_content">
  <div class="card_info_profile_title">
    <h4>Basic Info</h4>
  </div>
  <div class="card_info_profile_body">
    <ul class="list_with_icons">
      <li>
        <div class="icon">
          <img src="assets/images/icons/manager-icon.svg" alt="">
        </div>
        <div class="content">
          <h4>Manager</h4>
          <p>{{record.basicInfo?.managerName}}</p>
        </div>
      </li>
      <li>
        <div class="icon">
          <img src="assets/images/icons/phone-icon.svg" alt="">
        </div>
        <div class="content d-flex align-items-center justify-content-between flex-grow-1">
          <div class="flex-grow-1">
            <h4>Mobile No.</h4>
            <p>{{record.basicInfo?.contactNumberExtension + ' ' + record.basicInfo?.contactNumber}}</p>
          </div>
          <div class="conatnier_actions">
            <ng-container *ngIf="!record.basicInfo?.isMobileVerified">
              <span class="badge_status_progress orange">
                Mobile No. Not Verified
              </span>
              <a class="button_with_icon" mat-raised-button color="primary" appAccessControl
                accessRoles="SuperAdmin,,VLAdmin,LabAdmin,ScanningCenterAdmin,HospitalAdmin,ClinicAdmin"
                (click)="verifyMobile()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19.008" viewBox="0 0 16 19.008">
                  <g id="verify-icon" transform="translate(-8.887 -2.496)" opacity="0.85">
                    <path id="Path_17564" data-name="Path 17564"
                      d="M54.572,2.915,47.766.054a.686.686,0,0,0-.533,0L40.428,2.915A.7.7,0,0,0,40,3.564V7.042a11.776,11.776,0,0,0,7.238,10.906.687.687,0,0,0,.525,0A11.776,11.776,0,0,0,55,7.042V3.564A.7.7,0,0,0,54.572,2.915Zm-.961,4.127A10.377,10.377,0,0,1,47.5,16.533a10.377,10.377,0,0,1-6.111-9.491V4.034L47.5,1.465l6.111,2.569Z"
                      transform="translate(-30.613 3)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-width="1"
                      opacity="0.7" />
                    <path id="Path_17565" data-name="Path 17565"
                      d="M46.732,9.412,49.717,6.39a.688.688,0,0,1,.982,0,.709.709,0,0,1,0,.994L47.223,10.9a.688.688,0,0,1-.982,0L44.3,8.939a.709.709,0,0,1,0-.994.688.688,0,0,1,.982,0Z"
                      transform="translate(-30.613 3)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-width="1" />
                  </g>
                </svg>
                <span class="value">Verify Now</span>
              </a>
            </ng-container>
            <ng-container *ngIf="record.basicInfo?.isMobileVerified">
              <span class="badge_status_progress success">
                Mobile No. Verified
              </span>
            </ng-container>

          </div>
        </div>
      </li>
      <li>
        <div class="icon">
          <img src="assets/images/icons/email_address.svg" alt="">
        </div>
        <div class="content d-flex align-items-center justify-content-between flex-grow-1">
          <div class="flex-grow-1">
            <h4>Email Address</h4>
            <p>{{record.basicInfo?.email}}</p>
          </div>
          <div class="conatnier_actions">
            <ng-container *ngIf="!record.basicInfo?.isEmailVerified">
              <span class="badge_status_progress orange">
                Email Not Verified
              </span>
              <a class="button_with_icon" mat-raised-button color="primary" appAccessControl
                accessRoles="SuperAdmin,VLAdmin,LabAdmin,ScanningCenterAdmin,HospitalAdmin,ClinicAdmin"
                (click)="verfiyEmail()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19.008" viewBox="0 0 16 19.008">
                  <g id="verify-icon" transform="translate(-8.887 -2.496)" opacity="0.85">
                    <path id="Path_17564" data-name="Path 17564"
                      d="M54.572,2.915,47.766.054a.686.686,0,0,0-.533,0L40.428,2.915A.7.7,0,0,0,40,3.564V7.042a11.776,11.776,0,0,0,7.238,10.906.687.687,0,0,0,.525,0A11.776,11.776,0,0,0,55,7.042V3.564A.7.7,0,0,0,54.572,2.915Zm-.961,4.127A10.377,10.377,0,0,1,47.5,16.533a10.377,10.377,0,0,1-6.111-9.491V4.034L47.5,1.465l6.111,2.569Z"
                      transform="translate(-30.613 3)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-width="1"
                      opacity="0.7" />
                    <path id="Path_17565" data-name="Path 17565"
                      d="M46.732,9.412,49.717,6.39a.688.688,0,0,1,.982,0,.709.709,0,0,1,0,.994L47.223,10.9a.688.688,0,0,1-.982,0L44.3,8.939a.709.709,0,0,1,0-.994.688.688,0,0,1,.982,0Z"
                      transform="translate(-30.613 3)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-width="1" />
                  </g>
                </svg>
                <span class="value">Verify Now</span>
              </a>
            </ng-container>
            <ng-container *ngIf="record.basicInfo?.isEmailVerified">
              <span class="badge_status_progress success">
                Email Verified
              </span>
            </ng-container>
          </div>

        </div>
      </li>
      <li>
        <div class="icon">
          <img src="assets/images/icons/map-marker-icon.svg" alt="">
        </div>
        <div class="content">
          <h4>Address</h4>
          <p>
            {{record.basicInfo?.address}}
          </p>
          <p>
            {{record.city?.name}}, {{record.region?.name}}
          </p>
          <p>
            {{record.country?.name}}
          </p>
        </div>
      </li>
    </ul>
  </div>
</div>
