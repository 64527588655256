import { Subscription } from 'rxjs';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit, HostListener, ElementRef, NgZone, Input, OnDestroy } from '@angular/core';
import { DeepgramAudioService } from 'src/app/services/deepgram-audio.service';
import { Types } from 'src/app/models/configuration/reports/inputs/enums/Types';

@Component({
  selector: 'app-deepgram-field',
  templateUrl: './deepgram-field.component.html'
})
export class DeepgramFieldComponent implements OnInit, OnDestroy {
  @Input() formGroup!: FormGroup;
  @Input() fieldControlName: any;
  @Input() inputType: Types;
  @Input() allowVoiceRecognition: boolean;
  @Input() fieldLength: number;
  @Input() validationMessage: string;
  @Input() label: string;
  isUserSpeaking = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private deepgramAudioService: DeepgramAudioService,
    private elementRef: ElementRef,
    public zone: NgZone
  ) { }

  ngOnInit() {
  }

  // getMessage(context: any, message: any) {
  //   context.zone.run(() => {
  //     if (context.isUserSpeaking) {
  //       console.log(context.fieldControlName.value)
  //       context.fieldControlName.setValue(`${context.fieldControlName.value} ${message}`);
  //     }
  //   });
  // }

  startRecording() {
    this.isUserSpeaking = true;
    this.deepgramAudioService.saveRecord();
    this.subscriptions.push(
      this.deepgramAudioService.voiceToTextSubject.subscribe(text => {
        if (this.isUserSpeaking) {
          const totalTranscript = this.formGroup.controls[this.fieldControlName].value
            ? `${this.formGroup.controls[this.fieldControlName].value} ${text}`
            : text;
          this.formGroup.controls[this.fieldControlName].setValue(totalTranscript);
        }
      })
    );
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target) && this.isUserSpeaking && this.allowVoiceRecognition) {
      this.stopRecording();
    }
  }

  stopRecording() {
    this.isUserSpeaking = false;
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.deepgramAudioService.stopRecord();
  }

  hasError(errorName: string, controlName = null, index = null) {
    if (controlName && index !== null) {
      return (this.formGroup.controls[controlName] as FormArray).controls[index].hasError(errorName);
    } else {
      return this.formGroup.controls[controlName ? controlName : this.fieldControlName].hasError(errorName);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
