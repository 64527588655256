import { SPACE } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-select-with-search',
  templateUrl: './select-with-search.component.html'
})
export class SelectWithSearchComponent implements OnInit, OnChanges {
  @Input() formGroup: FormGroup;
  @Input() sourcData: any[];
  @Input() isSubmitted: boolean;
  @Input() controlName: any
  @Input() fieldLabel: String;
  @Input() multiple: boolean = true;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() dataLoading: boolean = false;
  @Input() hidden: boolean = false;
  @Input() key: string = 'name';
  //
  @ViewChild('searchInput', { static: false })
  searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('select', { static: true }) select: any;
  //
  hiddenList = [];

  constructor() { }

  ngOnInit(): void {
    this.select._handleKeydown = (event: KeyboardEvent) => {
      if (event.keyCode == SPACE)
        return
      if (!this.select.disabled) {
        this.select.panelOpen
          ? this.select._handleOpenKeydown(event)
          : this.select._handleClosedKeydown(event);
      }
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.isSubmitted && changes.isSubmitted.currentValue) {
      this.formGroup.controls[this.controlName].markAllAsTouched();
    }
  }

  applyFilter(value: string) {
    const filterValue = value.toLowerCase();
    this.hiddenList = [];
    this.sourcData.forEach((item) => {
      if (filterValue && !(this.key ? item.name : item).toLowerCase().includes(filterValue)) {
        this.hiddenList.push(item);
      }
    });
  }
  openEvent(opened) {
    if (opened) {
      this.searchInput.nativeElement.focus();
    } else {
      this.searchInput.nativeElement.value = '';
      this.applyFilter('');
    }
  }
  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  isInArray(parentObj, name) {
    return parentObj.some((obj) => (this.key ? obj[this.key] : obj) === name);
  }
}
