<div class="app-speech-input" [class.app-speech-input-listening]="isUserSpeaking" [formGroup]='formGroup'>
  <mat-form-field>
    <mat-label [hidden]="!label">{{label}}</mat-label>
    <textarea matInput class="app-speech-input-form-control" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" (keyup)="stopRecording()" [formControlName]="fieldControlName"
      *ngIf="inputType == 6">
      </textarea>
    <input matInput [formControlName]="fieldControlName" *ngIf="inputType == 5" (keyup)="stopRecording()">
    <mat-error *ngIf="hasError('required',fieldControlName)">Required</mat-error>
    <mat-error *ngIf="hasError('maxlength',fieldControlName)">
      Max Length is {{fieldLength}} Characters
    </mat-error>
    <mat-error *ngIf="hasError('pattern',fieldControlName)">{{ 'forms.Invalid number' | translate }}</mat-error>
    <mat-hint *ngIf="validationMessage">{{validationMessage}}</mat-hint>
    <div matSuffix [ngClass]="{'d-flex': allowVoiceRecognition}" [hidden]="!allowVoiceRecognition">
      <button type="button" mat-icon-button class="app-speech-input-btn" [hidden]="!isUserSpeaking"
        (click)="stopRecording()">
        <mat-icon class="soundwave">stop_circle</mat-icon>
      </button>
      <button type="button" mat-icon-button class="app-speech-input-btn" (click)="startRecording()"
        [hidden]="isUserSpeaking">
        <mat-icon>mic</mat-icon>
      </button>
    </div>
  </mat-form-field>
</div>