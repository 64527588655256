import { Component, OnInit } from '@angular/core';
import { SignalRService } from 'src/app/services/signal-rservice.service';

@Component({
  selector: 'app-blank-layout',
  templateUrl: './blank-layout.component.html'
})
export class BlankLayoutComponent implements OnInit {

  constructor(private signalRService: SignalRService) {
    if (!this.signalRService.ConnectionStarted) {
      this.signalRService.startConnection();
    }
  }

  ngOnInit(): void {
  }

}
