<div mat-dialog-title>
  <h2 class="d-block" *ngIf="data.isShare">
    Share Slides
    <span class="ml-0 mt-1">(Select slides you want to share to Concurel)</span>
  </h2>
  <h2 class="d-block" *ngIf="!data.isShare">
    View Multiple Slides
    <span class="ml-0 mt-1">(Select slides you want to open)</span>
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content class="assign-dialog-content multiple_slides" *ngIf="slidesList.length; else dataEmpty"
  infiniteScroll [scrollWindow]="false" [infiniteScrollDistance]="2" [infiniteScrollThrottle]="pageCount"
  (scrolled)="getNextPage()">
  <div class="page">

    <div class="">

      <table class="table table-primary table_assign">
        <thead>
          <tr>
            <th width="1%"></th>
            <th>Slide No.</th>
            <th>Stain type</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let record of slidesList; let i = index">
            <tr (click)="selectedSlides(record.slideId)" [class.active]="isInArray(record.slideId)">
              <td>
                <mat-checkbox [checked]="isInArray(record.slideId)"></mat-checkbox>
              </td>
              <td>
                #{{i + 1}}
              </td>
              <td>{{record.stainType.name}}</td>
            </tr>
          </ng-container>

        </tbody>

      </table>
    </div>
  </div>
</mat-dialog-content>

<div *ngIf='dataLoading' class="dataLoading">
  <mat-spinner class="m-auto" diameter="50"></mat-spinner>
</div>

<ng-template #dataEmpty>
  <ng-container *ngIf="!dataLoading">
    <app-empty-state [title]='"No Data Found"'></app-empty-state>
  </ng-container>
</ng-template>

<mat-dialog-actions align="end" [ngClass]="data.isShare ? 'justify-content-end' : 'justify-content-between'"
  class="assign-dialog-actions">
  <p *ngIf="!data.isShare">Min 2, Max 4 slides to view</p>
  <div>
    <button mat-raised-button color="grey" mat-dialog-close [disabled]='loading'>Cancel</button>
    <ng-container *ngIf="!data.notAuthenticated">
      <a mat-raised-button color="primary" mat-dialog-close
        *ngIf="selected.length >= 2 && selected.length <= 4 && !data.isShare"
        [routerLink]="this.data.IsEndorsement?
        ['viewer',data.caseIdType, data.caseId, 0,this.data.pathologistId ? this.data.pathologistId : '', this.data.pathologistType ? this.data.pathologistType : '', this.data.IsEndorsement?this.data.IsEndorsement:'']:
        ['viewer', data.caseIdType,data.caseId, 0,this.data.pathologistId ? this.data.pathologistId : '', this.data.requestId ? this.data.requestId : '']" [queryParams]="{slide: selected}">
        View Slides
      </a>
      <button mat-raised-button color="primary" *ngIf="data.isShare && selected.length" [class.loading]='loading'
        [disabled]='loading' (click)="loginConcurel()">Share to Concurel</button>
    </ng-container>
    <a mat-raised-button color="primary" mat-dialog-close *ngIf="data.notAuthenticated" [routerLink]="
    ['slideListShare',data.caseIdType, data.caseId, 0]" [queryParams]="{slide: selected}">
      View Slides
    </a>
  </div>
</mat-dialog-actions>