import { Component, OnInit, Input } from '@angular/core';
import { statusList } from 'src/app/models/sla/enums/statusList';

@Component({
  selector: 'app-sla-status',
  templateUrl: './sla-status.component.html'
})
export class SlaStatusComponent implements OnInit {
  @Input() status: statusList;
  slaStatus = statusList;

  constructor() { }

  ngOnInit() {
  }

}
