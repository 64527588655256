import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Lookup } from 'src/app/models/lookups/Lookup';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { LookupsService } from 'src/app/services/lookups.service';
import { LabsService } from 'src/app/services/labs.service';
import { LabDetails } from 'src/app/models/labs/LabDetails';
import { LabForm } from 'src/app/models/labs/LabForm';
import { LabType } from 'src/app/models/labs/enums/LabType';
import { CustomValidator } from 'src/app/helpers/custom.validator';
import { EntityEnumSLA } from 'src/app/models/sla/enums/EntityEnumSLA';
import { CommunicationMethodType } from 'src/app/models/global/enums/CommunicationMethodType';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SwalService } from 'src/app/services/swal.service';
export interface DialogData {
  recordId: number;
  record: LabDetails;
  addBranch: boolean;
  isOurProfile: boolean;
  isOurProfileVl: boolean;
}

@Component({
  selector: 'app-lab-form',
  templateUrl: './lab-form.component.html'
})
export class LabFormComponent implements OnInit {
  dataLoading = false;
  loading = false;
  hidePassword = true;

  recordId: number;
  isOurProfile: boolean;
  isOurProfileVl: boolean;
  details: LabDetails;
  disableLabType = false;
  labSCBind = false;

  formGroup: FormGroup;
  isSubmitted = false;

  entityList: any[] = [];
  mainLabsList: Lookup[] = [];
  countryList: Lookup[] = [];
  regionList: Lookup[] = [];
  cityList: Lookup[] = [];
  scanningCentersList: Lookup[] = [];

  // image file
  logo: any;
  logoSrc: string;
  maxFileSize = 0.8;
  fileTypeError = false;
  fileSizeError = false;

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  selectedCountryISO: string = CountryISO.SaudiArabia;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<LabFormComponent>,
    private formBuilder: FormBuilder,
    private service: LabsService,
    private lookupsService: LookupsService,
    private auth: AuthenticationService,
    private swalService: SwalService
  ) { }

  ngOnInit(): void {
    this.buildForm();

    if (this.data.isOurProfile) {
      this.isOurProfile = this.data.isOurProfile;
      this.bindDataToUpdate();
    } else if (this.data.isOurProfileVl) {
      this.isOurProfileVl = this.data.isOurProfileVl;
      this.bindDataToUpdate();
    } else {
      if (this.data && this.data.record && this.data.record.id) {
        this.recordId = this.data.recordId;
        this.bindDataToUpdate();
      }
    }

    if (this.data && this.data.addBranch) {
      this.formGroup.controls.type.setValue(LabType.BRANCH);
      this.formGroup.controls.mainLab.setValue(this.data.recordId);
    }

    if (this.data && this.data.record && (
      this.data.addBranch // Add new branche
      || (this.data.record.branches && this.data.record.branches.length) // Has branches
      || this.data.record.casesCount
    )) {
      this.disableLabType = true;
    }
    this.getAllMain();
    this.getListHospitalsClinicsLookup();
    this.getCountries();
  }

  getDetails() {
    this.dataLoading = true;
    this.service.getDetails(this.recordId).subscribe(
      data => {
        this.details = data as LabDetails;
        this.dataLoading = false;
        this.bindDataToUpdate();
      },
      error => {
        this.dataLoading = false;
      });
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      type: [LabType.MAINLAB],
      entityId: [null],
      mainLab: [null],
      isHospitalClinic: [false],
      Lab: [null, CustomValidator.noWhiteSpace],
      contactNumber: [null, Validators.required],
      email: [null, [
        Validators.required,
        Validators.email,
        Validators.pattern('[a-zA-Z0-9._-]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}'),
      ]],
      country: [null, Validators.required],
      region: [null, Validators.required],
      cityId: [null, Validators.required],
      address: [null, CustomValidator.noWhiteSpace],
      managerFirstName: [null, CustomValidator.noWhiteSpace],
      managerLastName: [null, CustomValidator.noWhiteSpace],
      communicationMethod: this.formBuilder.array([
        this.formBuilder.group({
          selectedMethod: [false],
          CommunicationMethodType: [CommunicationMethodType.NETWORK],
          NetworkPath: [null],
          UserName: [null],
          Password: [null],
          IsSFtp: [false],
          CephAccessKeyId: [null],
          CephSecretKey: [null],
          CephApiURL: [null],
          CephDefaultBucket: [null],
        }),
        this.formBuilder.group({
          selectedMethod: [false],
          CommunicationMethodType: [CommunicationMethodType.FTP],
          NetworkPath: [null],
          UserName: [null],
          Password: [null],
          IsSFtp: [false],
          CephAccessKeyId: [null],
          CephSecretKey: [null],
          CephApiURL: [null],
          CephDefaultBucket: [null],
        }),
        this.formBuilder.group({
          selectedMethod: [false],
          CommunicationMethodType: [CommunicationMethodType.LOCAL],
          NetworkPath: [null],
          UserName: [null],
          Password: [null],
          IsSFtp: [false],
          CephAccessKeyId: [null],
          CephSecretKey: [null],
          CephApiURL: [null],
          CephDefaultBucket: [null],
        }),
        this.formBuilder.group({
          selectedMethod: [false],
          CommunicationMethodType: [CommunicationMethodType.CEPH],
          NetworkPath: [null],
          UserName: [null],
          Password: [null],
          IsSFtp: [false],
          CephAccessKeyId: [null],
          CephSecretKey: [null],
          CephApiURL: [null],
          CephDefaultBucket: [null],
        })
      ]),
      // canSendToVL: [true],
      // canSendToSC: [true],
      // scanningCenterIds: [null],
    });
  }

  hasError(controlName: string, errorName: string, index = null) {
    if (index !== null) {
      return this.communicationMethods.controls[index]['controls'][controlName].hasError(errorName);
    } else {
      return this.formGroup.controls[controlName].hasError(errorName);
    }
  }

  get communicationMethods() {
    return this.formGroup.controls.communicationMethod as FormArray;
  }
  getListHospitalsClinicsLookup() {
    const query = { WithLabs: true };
    this.lookupsService.getListHospitalsClinicsLookup(query).subscribe(
      data => {
        this.entityList = data as [];
        if (this.data && this.data.record && this.data.record.entity) {
          this.formGroup.controls.entityId.setValue(
            this.entityList.find(item => item.id === this.data.record.entity.entityId && item.code === this.data.record.entity.entityType)
          );
          this.formGroup.controls.isHospitalClinic.setValue(true);
        }
      }, err => {
        console.error(err);
      });
  }

  getAllMain() {
    this.service.getAllMain(this.recordId).subscribe(
      data => {
        this.mainLabsList = data as [];
      }, err => {
        console.error(err);
      });
  }
  getCountries() {
    this.lookupsService.getCountries().subscribe(
      data => {
        this.countryList = data as [];
        if (!this.recordId && !this.isOurProfile && !this.isOurProfileVl) {
          this.regionList = [];
          this.cityList = [];
          // this.scanningCentersList = [];
          // this.formGroup.controls.scanningCenterIds.setValue(null);
          this.formGroup.controls.country.setValue(this.countryList.find(item => item.isDefault).id);
          this.getRegions();
        }
      }, err => {
        console.error(err);
      });
  }
  getRegions(changed = false) {
    this.lookupsService.getRegions(this.formGroup.controls.country.value).subscribe(
      data => {
        this.regionList = data as [];
        if (changed) {
          this.cityList = [];
          // this.scanningCentersList = [];
          this.formGroup.controls.region.setValue(null);
          this.formGroup.controls.cityId.setValue(null);
          // this.formGroup.controls.scanningCenterIds.setValue(null);
        }
      }, err => {
        console.error(err);
      });
  }
  getCities(changed = false) {

    this.lookupsService.getCities(
      this.formGroup.controls.country.value,
      this.formGroup.controls.region.value).subscribe(
        data => {
          this.cityList = data as [];
          // this.scanningCentersList = [];
          if (changed) {
            this.formGroup.controls.cityId.setValue(null);
            // this.formGroup.controls.scanningCenterIds.setValue(null);
          }
        }, err => {
          console.error(err);
        });
  }
  /* GetScanningCenterByCity(changed = false) {
    this.scService.GetScanningCenterByCity(this.formGroup.controls.cityId.value).subscribe(
      data => {
        this.scanningCentersList = data as [];
        if (changed) {
          this.formGroup.controls.scanningCenterIds.setValue(null);
        }
        if (data && (this.labId || this.isOurProfile || this.isOurProfileVl) && !this.labSCBind) {
          this.labSCBind = true;
          this.formGroup.controls.scanningCenterIds.setValue(
            this.bindMatMultiSelect(this.data.record.scanningCenters, this.scanningCentersList));
        }
      }, err => {
        console.error(err);
      });
  } */

  bindDataToUpdate(record = this.data.record) {
    this.formGroup.controls.type.setValue(record.entity ? LabType.MAINLAB : record.labType);
    this.formGroup.controls.mainLab.setValue(record.mainLab ? record.mainLab.id : null);
    this.formGroup.controls.Lab.setValue(record.labName);
    if (record.logo && record.logo) { this.logoSrc = record.logo; }
    this.formGroup.controls.contactNumber.setValue(record.basicInfo.contactNumber);
    this.selectedCountryISO = record.basicInfo.contactNumberFlag;
    this.formGroup.controls.email.setValue(record.basicInfo.email);
    this.formGroup.controls.country.setValue(record.country.id);
    this.formGroup.controls.region.setValue(record.region.id);
    this.formGroup.controls.cityId.setValue(record.city.id);
    this.formGroup.controls.address.setValue(record.basicInfo.address);
    this.formGroup.controls.managerFirstName.setValue(record.basicInfo.managerFirstName);
    this.formGroup.controls.managerLastName.setValue(record.basicInfo.managerLastName);
     this.communicationMethods.controls.forEach(group => {
        const item = record.communicationMethod.find(method =>
        method.communicationMethodType === group['controls'].CommunicationMethodType.value);

        if (item) {
        group['controls'].selectedMethod.setValue(true);
        group['controls'].CommunicationMethodType.setValue(item.communicationMethodType);
        group['controls'].NetworkPath.setValue(item.networkPath);
        group['controls'].UserName.setValue(item.userName);
        group['controls'].Password.setValue(item.password);
        group['controls'].IsSFtp.setValue(item.isSFtp);
        group['controls'].CephAccessKeyId.setValue(item.cephAccessKeyId);
        group['controls'].CephSecretKey.setValue(item.cephSecretKey);
        group['controls'].CephApiURL.setValue(item.cephApiURL);
        group['controls'].CephDefaultBucket.setValue(item.cephDefaultBucket);
      }
    });
    // this.formGroup.controls.canSendToVL.setValue(record.canSendToVL);
    // this.formGroup.controls.canSendToSC.setValue(record.canSendToSC);
    // this.formGroup.controls.scanningCenterIds.setValue(record.scanningCenters);
    this.getRegions();
    this.getCities();
    // this.GetScanningCenterByCity();
  }

  bindMatMultiSelect(data, parent) {
    const selectedList = [];
    if (parent && data) {
      parent.forEach(item => {
        if (data.some(obj => obj.id === item.id) || data.some(obj => obj.id === item.roleId)) {
          selectedList.push(item);
        }
      });
    }
    return selectedList;
  }

  saveRecord() {
    this.isSubmitted = true;
    if (this.formGroup.valid && this.logoSrc && this.checkCommunicationMethod()) {
      this.loading = true;
      let data: LabForm;
      data = {
        lab: this.formGroup.controls.Lab.value,
        ContactNumberFlag: this.formGroup.controls.contactNumber.value.countryCode,
        ContactNumberExtension: this.formGroup.controls.contactNumber.value.dialCode,
        ContactNumber: this.formGroup.controls.contactNumber.value.number,
        email: this.formGroup.controls.email.value,
        cityId: this.formGroup.controls.cityId.value,
        streetaddress: this.formGroup.controls.address.value,
        managerFirstName: this.formGroup.controls.managerFirstName.value,
        managerLastName: this.formGroup.controls.managerLastName.value,
        communicationMethod: [],
        clinicId: null,
        hospitalId: null
      } as LabForm;
      if (!this.isOurProfileVl) {
        /*  data.canSendToVL = this.formGroup.controls.canSendToVL.value;
         data.canSendToSC = this.formGroup.controls.canSendToSC.value; */
        data.type = this.formGroup.controls.isHospitalClinic.value ? LabType.MAINLAB : this.formGroup.controls.type.value;
        data.mainLab = this.formGroup.controls.mainLab.value;
      }
      if (this.formGroup.controls.type.value === LabType.MAINLAB) {
        data.mainLab = null;
      }
      if (this.formGroup.controls.isHospitalClinic.value) {
        data.hospitalId = this.formGroup.controls.entityId.value.code === EntityEnumSLA.hospital ?
          this.formGroup.controls.entityId.value.id : null;
        data.clinicId = this.formGroup.controls.entityId.value.code === EntityEnumSLA.clinic ?
          this.formGroup.controls.entityId.value.id : null;
      }
      this.formGroup.controls.communicationMethod.value.forEach(element => {
        if (element.selectedMethod) {
          data.communicationMethod.push(
            {
              CommunicationMethodType: element.CommunicationMethodType,
              NetworkPath: element.NetworkPath,
              UserName: element.UserName,
              Password: element.Password,
              IsSFtp: element.IsSFtp,
              CephAccessKeyId: element.CephAccessKeyId,
              CephSecretKey: element.CephSecretKey,
              CephApiURL: element.CephApiURL,
              CephDefaultBucket: element.CephDefaultBucket,
            }
          );
        }
      });

      if (this.recordId) { data.labId = this.recordId; }

      // if (this.formGroup.controls.canSendToSC.value) {
      //   data.scanningCenterIds = [];
      //   this.formGroup.controls.scanningCenterIds.value.forEach(element => {
      //     data.scanningCenterIds.push(element.id);
      //   });
      // }
      if (this.isOurProfile || this.isOurProfileVl) {
        const type = this.isOurProfileVl ? 'profileVl' : null;
        this.service.updateLabProfile(data, this.logo, type).subscribe(() => {
          this.loading = false;
          this.swalService.swalSuccess('Updated Successfully');
          this.dialogRef.close(true);
        }, err => {
          this.loading = false;
          this.handleErrors(err.detail);
        });
      } else {
        this.service.saveRecord(data, this.logo).subscribe(() => {
          this.loading = false;
          this.swalService.swalSuccess(this.recordId ? 'Updated Successfully' : 'Saved Successfully');
          this.dialogRef.close(true);
        }, err => {
          this.loading = false;
          this.handleErrors(err.detail);
        });
      }
    }
  }

  handleErrors(errors) {
    errors = JSON.parse(errors);
    errors.ErrorDTO.forEach(error => {
      if (error.ErrorCode === 'Repeated') {
        this.formGroup.controls[error.ErrorDetail].setErrors({ notUniqe: true });
      }
    });
  }

  // File preview with validation
  changeFileListener($event): void {
    const file: File = $event.target.files[0];
    if (file && this.validateFile(file)) {
      this.readThis(file);
      this.logo = file;
    } else {
      this.logoSrc = undefined;
      this.logo = undefined;
    }
  }
  readThis(file: File): void {
    const myReader: FileReader = new FileReader();
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      this.logoSrc = myReader.result as string;
    };
  }
  validateFile(file: File): any {
    if (this.fileType(file.name)) {
      this.fileTypeError = false;
      if ((file.size / (1024 * 1024)) <= this.maxFileSize) {
        this.fileSizeError = false;
      } else {
        this.fileSizeError = true;
        return false;
      }
    } else {
      this.fileTypeError = true;
      return false;
    }
    return true;
  }
  fileType(fileName): any {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'jpeg':
      case 'jpg':
        return 'jpg';
      case 'png':
        return 'png';
      default:
        return false;
    }
  }

  changeSlide(e) {
    if (e.checked) {
      this.formGroup.controls.type.setValue(LabType.MAINLAB);
    }
  }

  hasPermission(permission: string) {
    return this.auth.hasPermission(permission);
  }

  autoFillInput(controlName: string) {
    this.formGroup.controls[controlName].reset();
  }

  checkCommunicationMethod(): boolean {
    return !!this.communicationMethods.controls.find(item => item['controls'].selectedMethod.value === true);
  }
}
