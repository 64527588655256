import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthUser } from 'src/app/models/auth/AuthUser';
import { EntityEnumSLA } from 'src/app/models/sla/enums/EntityEnumSLA';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { VerifyMobileComponent } from '../../verify-mobile/verify-mobile.component';

@Component({
  selector: 'app-entity-basic-info',
  templateUrl: './entity-basic-info.component.html'
})
export class EntityBasicInfoComponent implements OnInit {
  @Input() record: any;
  @Input() entityId: number;
  @Input() entityType: EntityEnumSLA;
  @Output() verifyMobileEvent = new EventEmitter<boolean>();
  @Output() verfiyEmailEvent = new EventEmitter<boolean>();
  currentUser = new AuthUser();

  constructor(
    public dialog: MatDialog,
    private auth: AuthenticationService,
    public translate: TranslateService
  ) {
    this.currentUser = this.auth.currentUserValue;
  }

  ngOnInit() {
  }

  verifyMobile() {
    const dataRecord = {
      entityId: this.entityId ? this.entityId : this.currentUser.ObjectValue,
      entityType: this.entityType,
      title: 'Verify Mobile of ' + this.entityType,
      message: ' We have sent a one-time password to the mobile number that is registered for your ' + + this.entityType + '.'
    };
    const dialogRef = this.dialog.open(VerifyMobileComponent, {
      width: '750px',
      data: dataRecord
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.verifyMobileEvent.emit(true);
      }
    });
  }

  verfiyEmail() {
    this.verfiyEmailEvent.emit(true);
  }

}
