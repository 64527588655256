import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RolesEnum } from 'src/app/models/users/roles/enums/roles';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-entity-total-cases',
  templateUrl: './entity-total-cases.component.html'
})

export class EntityTotalCasesComponent implements OnInit {
  @Input() casesCount: number;
  @Input() openCasesCount: number;
  @Input() closedCasesCount: number;
  @Input() entityName: string;
  @Input() isOurProfile: boolean;

  constructor(private router: Router, private auth: AuthenticationService) { }

  ngOnInit() {
  }

  getCases(entityName) {
    if (this.hasPermission(RolesEnum.SuperAdmin)) {
      this.router.navigate(['/admin/cases/LabsCases'], { queryParams: { labName: entityName } });
    } else {
      this.router.navigate(['/admin/cases/BranchsCases'], { queryParams: { labName: entityName } });
    }
  }

  hasPermission(permission: string) {
    return this.auth.hasPermission(permission);
  }

}
