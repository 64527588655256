import { BlankLayoutComponent } from './layout/blank-layout/blank-layout.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import { ErrorComponent } from './pages/error/error.component';
import { NotAuthComponent } from './pages/not-auth/not-auth.component';
import { LabProfileComponent } from './features/labs/lab-profile/lab-profile.component';
import { ScProfileComponent } from './features/scanning-center/sc-profile/sc-profile.component';
import { UserProfileComponent } from './features/users/user-profile/user-profile.component';
import { HospitalProfileComponent } from './features/hospitals/hospital-profile/hospital-profile.component';
import { ClinicProfileComponent } from './features/clinics/clinic-profile/clinic-profile.component';
import { LabsComponent } from './features/labs/labs.component';
import { AppCustomPreloader } from 'src/app/helpers/app-routing-loader';
import { SlidesListSharedComponent } from 'src/app/partial/slides/slides-list-shared/slides-list-shared.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/admin/myprofile',
    pathMatch: 'full'
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule)
      }
    ]
  }, {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'myprofile', component: UserProfileComponent },
      { path: 'notAuthorized', component: NotAuthComponent },
      {
        path: 'dashboard',
        loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./features/users/users.module').then(m => m.UsersModule)
      },
      { path: 'labs', loadChildren: () => import('./features/labs/labs.module').then(m => m.LabsModule) },
      { path: 'our-labs', component: LabsComponent },
      { path: 'labProfile', component: LabProfileComponent },
      { path: 'vlProfile', component: LabProfileComponent },
      {
        path: 'scanning-centers',
        loadChildren: () => import('./features/scanning-center/scanning-center.module').then(m => m.ScanningCenterModule)
      },
      { path: 'scProfile', component: ScProfileComponent },
      { path: 'branches', loadChildren: () => import('./features/branches/branches.module').then(m => m.BranchesModule) },
      { path: 'branches/lab/:id', component: LabProfileComponent },
      { path: 'branches/scanning-center/:id', component: ScProfileComponent },
      {
        path: 'configuration', loadChildren: () => import('./features/configuration/configuration.module').then(m => m.ConfigurationModule),
      },
      {
        path: 'cases',
        loadChildren: () => import('./features/cases/cases.module').then(m => m.CasesModule),
        data: { preload: true }
      },
      { path: 'hospitals', loadChildren: () => import('./features/hospitals/hospitals.module').then(m => m.HospitalsModule) },
      { path: 'hospitalProfile', component: HospitalProfileComponent },
      { path: 'clinics', loadChildren: () => import('./features/clinics/clinics.module').then(m => m.ClinicsModule) },
      { path: 'clinicProfile', component: ClinicProfileComponent },
      { path: 'sla', loadChildren: () => import('./features/sla/sla.module').then(m => m.SlaModule) },
      { path: 'Pathologists', loadChildren: () => import('./features/pathologists/pathologists.module').then(m => m.PathologistsModule) },
      { path: 'requests', loadChildren: () => import('./features/requests/requests.module').then(m => m.RequestsModule) },
      { path: 'payments', loadChildren: () => import('./features/payments/payments.module').then(m => m.PaymentsModule) },
      { path: '**', component: ErrorComponent },
    ]
  },
  {
    path: 'slideShare', // Anonymous Slide Link
    component: BlankLayoutComponent,
    children: [
      { path: '', loadChildren: () => import('./features/viewer/viewer.module').then(m => m.ZoomViewerModule) },
    ]
  },
  {
    path: 'slideShareEnv', // Anonymous Slide Environment Link
    component: BlankLayoutComponent,
    children: [
      { path: '', loadChildren: () => import('./features/viewer/viewer.module').then(m => m.ZoomViewerModule) },
    ]
  },
  {
    path: 'slideListShare', // Anonymous Slide List
    component: BlankLayoutComponent,
    children: [
      { path: '', component: SlidesListSharedComponent },
      { path: '', loadChildren: () => import('./features/viewer/viewer.module').then(m => m.ZoomViewerModule) },
    ]
  },
  {
    path: 'viewer',
    component: BlankLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', loadChildren: () => import('./features/viewer/viewer.module').then(m => m.ZoomViewerModule) },
      { path: '**', component: ErrorComponent },
    ]
  },
  { path: '**', component: ErrorComponent },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
      preloadingStrategy: AppCustomPreloader
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
