import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Details } from '../models/cases/Details';
import { CaseType } from '../models/cases/enums/CaseType';
import { RolesEnum } from '../models/users/roles/enums/roles';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CasesService {

  refreshDetails = new BehaviorSubject<boolean>(false);
  reloadDetails = new BehaviorSubject<boolean>(false);
  disabledActions = new BehaviorSubject<boolean>(false);
  returnUrl = new BehaviorSubject<string>(null);
  enableDeclineApprove = new BehaviorSubject<boolean>(false);
  enableDeclineApproveRequest = new BehaviorSubject<boolean>(false);
  enableSubmitUpdatesDetails = new BehaviorSubject<boolean>(false);
  enableUploadSlides = new BehaviorSubject<boolean>(false);
  enableUpdateRequest = new BehaviorSubject<boolean>(false);
  enableWithdrawRequest = new BehaviorSubject<boolean>(false);
  enableWithdrawRequestEndorsment = new BehaviorSubject<boolean>(false);
  enableSendbackRequest = new BehaviorSubject<boolean>(false);
  enableEditingReport = new BehaviorSubject<boolean>(false);
  enableEditingComments = new BehaviorSubject<boolean>(false);
  enableEditingFeedback = new BehaviorSubject<boolean>(false);
  enableEditingUpdates = new BehaviorSubject<boolean>(false);
  enableEditingPass = new BehaviorSubject<boolean>(false);
  enableEditingAfterPass = new BehaviorSubject<boolean>(false);
  openDialog = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private router: Router
  ) { }

  getAll(params, casesType: CaseType, isPahthologist = false): any {
    if (casesType === CaseType.RefCase) {
      return this.http.get(`${environment.apiUrl}/Case/ListReferredCases`, { params });
    } else if (isPahthologist) {
      return this.http.get(`${environment.apiUrl}/Case/ListPatholgyCases`, { params });
    } else {
      return this.http.get(`${environment.apiUrl}/Case/ListCases`, { params });
    }
  }

  getDetails(params) {
    return this.http.get(`${environment.apiUrl}/Case/GetCaseDetails`, { params });
  }

  GetPatientDetails(params): any {
    return this.http.get(`${environment.apiUrl}/Case/GetPatientDetails`, { params });
  }

  saveRecord(data, caseID = null) {
    if (caseID) {
      return this.http.put(`${environment.apiUrl}/Case/UpdateCase`, data);
    } else {
      return this.http.post(`${environment.apiUrl}/Case/CreateCase`, data);
    }
  }

  deleteRecord(id) {
    return this.http.delete(`${environment.apiUrl}/Case/DeleteCase?CaseId=${id}`);
  }

  getSelectedSlides(params): any {
    return this.http.get(`${environment.apiUrl}/Case/GetSelectedSlides`, { params });
  }

  updateCaseStatus(obj, isReferred = false): any {
    if (isReferred) {
      return this.http.post(`${environment.apiUrl}/DigitizationRequest/ChangeCaseRefferredStatus`, obj);
    } else {
      return this.http.put(`${environment.apiUrl}/Case/UpdateCaseStatus`, obj);
    }
  }

  changeCaseRefferredStatus(obj): any {
    return this.http.post(`${environment.apiUrl}/DigitizationRequest/ChangeCaseRefferredStatus`, obj);
  }

  addPatientHistory(patient): any {
    return this.http.put(`${environment.apiUrl}/Case/AddPatientHistory`, patient);
  }

  getAssignedPathologiest(caseDetails: Details, pathologistId) {
    if (pathologistId) {
      caseDetails.assignedPathologist = this.selectPathologiestById(caseDetails, pathologistId);
    } else {
      if (this.auth.hasPermission(RolesEnum.Pathologist) || this.auth.hasPermission(RolesEnum.VLPathologist)) {
        caseDetails.pathologiests.map(person => {
          if (person.isAssignedPathologist) {
            caseDetails.assignedPathologist = person;
            caseDetails.isAssignedPathologist = true;
          }
        });
      }
    }
    return caseDetails;
  }
  selectPathologiestById(caseDetails, pathologistId) {
    const index = caseDetails.pathologiests.findIndex(user => user.id === pathologistId);
    if (pathologistId) {
      if (index !== -1) {
        return caseDetails.pathologiests[index];
      } else {
        this.router.navigate(['/admin/cases/details', caseDetails.caseId]);
      }
    }
  }

  ReassessmentCase(caseId): any {
    return this.http.post(`${environment.apiUrl}/Case/ReassessmentCase`, { caseId });
  }

  getTissuesSubspecialityLookup() {
    return this.http.get(`${environment.apiUrl}/ManageUsers/GetTissuesSubspecialityLookup`);
  }

  updateSpecimenSection(specimen) {
    return this.http.put(`${environment.apiUrl}/Case/UpdateSpecimenSection`, specimen);
  }

  getListCasesToBeReferred(params) {
    return this.http.get(`${environment.apiUrl}/Case/ListCasesToBeReferred`, { params });
  }
  archiveAction(params) {
    return this.http.get(`${environment.apiUrl}/Case/ArchiveAction`, { params });
  }

  getCaseDetailsFromDemo(params) {
    return this.http.get(`${environment.apiUrl}/Case/GetCaseDetailsFromDemo`, { params });
  }

  getDiffDate(params: { case?: any, date?: Date, status?: string, type?: string, procedure?: string }): boolean {
    let callBackBool = false;
    let firstList = params.case.caseLog.filter(item => item.status === params.status && item.procedure === params.status);
    let secondList = [];
    if (params.procedure) { firstList = params.case.caseLog.filter(item => item.procedure === params.procedure); }

    if (!params.date) {
      secondList = params.case.caseLog.filter(item => item.status === params.case.caseStatus && item.procedure === params.status);
    }

    if (firstList.length > 0 && (params.date || secondList.length > 0)) {
      if (secondList.length > 0) { params.date = secondList[0].creationDate; }
      let timeDiff = Number(new Date(firstList[0].creationDate).getTime() - new Date(params.date).getTime());
      if (params.type && params.type === 'reverse') {
        timeDiff = Number(new Date(params.date).getTime() - new Date(firstList[0].creationDate).getTime());
      }
      if (timeDiff >= 0) { callBackBool = true; }
    }
    return callBackBool;
  }

  updateCaseDescription(data) {
    return this.http.put(`${environment.apiUrl}/Case/UpdateCaseDescription`, data);
  }

  UploadSpecimenImage(data) {
    const formData = new FormData();
    if (data.file && data.file.name) {
      formData.append('icon', data.file, data.file.name);
    };
    return this.http.post(`${environment.apiUrl}/Case/UploadSpecimenImage?specimenImageId=${data.id}`, formData);
  }
  RemoveSpecimenImage(id) {
    return this.http.delete(`${environment.apiUrl}/Case/RemoveSpecimenImage?specimenImageId=${id}`);
  }
  SubmitCaseContainer(data) {
    return this.http.post(`${environment.apiUrl}/Case/SubmitCaseContainer`, data);
  }


  changeTechnologistStatus(obj) {
    return this.http.post(`${environment.apiUrl}/Case/ChangeTechnologistStatus`, obj);
  }

  listCaseContainers(caseId: any, params: any) {
    return this.http.get(`${environment.apiUrl}/Case/ListCaseContainers/${caseId}`, { params });
  }

  SubmitCaseCongruence(data) {
    return this.http.post(`${environment.apiUrl}/Case/SubmitCaseCongruence`, data);
  }
  archiveCaseSlides(data: any) {
    return this.http.put(`${environment.apiUrl}/Case/ArchiveCaseSlides`, data);
  }
  getCaseTags(params: any) {
    return this.http.get(`${environment.apiUrl}/Case/GetCaseTags`, {params});
  }
}
