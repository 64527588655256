<ng-container *ngIf="typeCase === 'Case'">
  <span [ngSwitch]="currentStatus">
    <span class="badge-status white font_medium" *ngSwitchCase="'IDLE'">
      Case Saved as a Draft
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'CASE_SUBMITTED'">
      Case Submitted
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'UPLOAD_SLIDES'">
      {{dRequestStatus === 'UPLOADED_SLIDES' && dRequestType === 'VIRTUAL_LAB'?'VL ':''}}
      {{ dRequestStatus === 'UPLOADED_SLIDES' && dRequestType === 'SCANNING_CENTER'?'Scanning Center ':''}}
      <!-- Technician --> Uploaded Slide(s)
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'SCANNING_CENTER_UPLOADED_SLIDES'">
      Scanning Center Technician Uploaded Slide(s)
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'VIRTUAL_LAB_UPLOADED_SLIDES'">
      VL Technician Uploaded Slide(s)
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'SUPERVISOR_REJECT_UPLOADED_SLIDES'">
      {{
      dRequestProcedure === 'SCANNING_PROCESS' || dRequestProcedure === 'PROCESS_ONLY'?
      'VL Supervisor ':'Supervisor '
      }}
      {{dRequestProcedure === 'PROCESS_ONLY'?'Sent the Case Back':'Declined the Uploaded Slide(s)'}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'SUPERVISOR_REJECT_CASE_DETAILS_CASE'">
      {{
      hasPermission('LabSupervisor') || hasPermission('VLSupervisor')?
      'You Declined the Case Details' : 'Supervisor Sent the Case Back'
      }}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'CASE_SUBMITTED_AFTER_SENT_BACK_CASE_DETAILS'">
      Technician Updated the Case after Sent Back
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'CASE_REUPLOADED_SLIDES'">
      {{entityTypeEnum[dRequestType]}}
      Technician Reuploaded Slide(s)
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'WAITING_VL_SUPERVISOR_PROCESS'">
      {{
      dRequestStatus === 'SUBMITTED'?
      'Technician Updated the Case':
      dRequestType === 'VIRTUAL_LAB'?
      dRequestProcedure === 'PROCESS_ONLY'?
      'Case Referred to VL for Processing':'VL'
      :'Scanning Center '
      }}
      {{
      dRequestProcedure === 'PROCESS_ONLY'? '':
      dRequestStatus === 'REUPLOADED_SLIDES'?
      'Reuploaded Slide(s)':'Uploaded Slide(s)'
      }}
    </span>
    <span class="badge-status success font_medium"
      [ngClass]="{'primary': hasPermission('MatchMaker') || hasPermission('VLMatchMaker')}"
      *ngSwitchCase="'LAB_SUPERVISOR_APPROVED'">
      {{
      hasPermission('LabSupervisor') || hasPermission('VLSupervisor')?
      hasPermission('MatchMaker') || hasPermission('VLMatchMaker')?
      'You Approved the Case':
      hasPermission('VLSupervisor') ? 'VL Supervisor Approved the Case':' Supervisor Approved the Case' :
      hasPermission('MatchMaker') || hasPermission('VLMatchMaker')?
      'New Case Submitted':'Supervisor Approved the Case'
      }}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'CASE_WITH_REQUEST'">
      Case Submitted / Referred to {{entityTypeEnum[dRequestType]}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'CASE_HAS_REJECTED_REQUEST'">
      {{entityTypeEnum[dRequestType]}}
      Technician Not Accept the Case
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'CLOSED'">
      the Case Closed
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'CLOSED_LAB_DELETED'">
      the Case Closed
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'SUPERVISOR_REJECT_CASE_DETAILS'">
      {{
      hasPermission('LabSupervisor') || hasPermission('VLSupervisor')?
      'You Declined the Case Details':'Supervisor Sent the Case Back'
      }}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'CASE_SUBMITTED_DETAILS'">
      Technician Updated the Case after Sent Back
    </span>
    <span class="badge-status success font_medium" *ngSwitchCase="'LAB_SUERVISOR_REVIEWED'">
      {{
      dRequestProcedure === 'SCANNING_ONLY' && hasPermission('MatchMaker') ? 'New Case Submitted':
      !dRequestProcedure && hasPermission('VLMatchMaker') ? 'New Case Submitted':
      dRequestProcedure === 'PROCESS_ONLY' || dRequestProcedure === 'SCANNING_PROCESS'?
      'VL Supervisor Approved the Case':
      hasPermission('LabSupervisor') || hasPermission('VLSupervisor')?
      'You Approved the Case':' Supervisor Approved the Case'
      }}

    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'CASE_HAS_APPROVED_BY_VIRTUAL_LAB'">
      VL Technician Uploaded Slide(s)
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'CASE_HAS_APPROVED_BY_SCANNING_CENTER'">
      Scanning Center Technician Uploaded Slide(s)
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'TECHNICIAN_REFUSED_SUPERVISOR_COMMENTS'">
      {{entityTypeEnum[dRequestType]}}
      Technician Not Accept the Case
    </span>
    <span class="badge-status primary-2 font_medium" *ngSwitchCase="'MATCH_MAKING'">
      {{hasPermission('Pathologist') || hasPermission('VLPathologist')?'New Case Assigned':'Assigned Pathologist'}}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'MATCHMAKING_RESET'">
      New Case Submitted
    </span>
    <span class="badge-status primary-2 font_medium" *ngSwitchCase="'ACTIVE'"
      [ngClass]="{'move-F': selectdEndorsement && (currentStatus === 'ACTIVE' || currentStatus === 'ACCEPTED'),'yellow':pathologistSource === 'ENDORSEMENT'}">
      {{
      selectdSecondOpinion && (selectdSecondOpinion.status === 'ACTIVE' || selectdSecondOpinion.status === 'ACCEPTED' ||
      selectdSecondOpinion.status === 'SENT_BACK_SECOND_OPINION')?
      'You Asked for a Second Opinion':

      selectdEndorsement && (currentStatus === 'ACTIVE' || currentStatus === 'ACCEPTED')?
      'You Submitted the Report':

      isAssignedPathologist?
      pathologistSource === 'PRIMARY'? 'New Case Assigned':
      pathologistSource === 'ENDORSEMENT'?'New Case Assigned for Endorsement':
      pathologistSource === 'GROSSING' && isAssignedPathologist? 'New Case Assigned':
      'New Case Assigned for Second Opinion':
      pathologistSource === 'GROSSING' && !isAssignedPathologist? 'Pathologist Assigned for Grossing':
      hasPermission('LabTechnologist') || hasPermission('VLabTechnologist')?'New Case Assigned':
      'Assigned Pathologist'
      }}
    </span>
    <span class="badge-status active font_medium" *ngSwitchCase="'ACCEPTED'">
      {{
      selectdSecondOpinion?'Second pathologist approve the case':
      selectdEndorsement?'Endorsement approve the case':
      isAssignedPathologist?'You Accept the Case':'Pathologist Accepted th case'}}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'REOPENED'">
      Reopened the Case
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'WITH_DRAW'">
      {{isAssignedPathologist?'You Withdrew from the Case':'Pathologist Withdrew from the Case'}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'secondOpnionWithdrow'">
      Second Opinion withdrawal from the request
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'endorsmentWithdrow'">
      Endorsement withdrawal from the request
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'secondOpnionReject'">
      Second Opinion Reject the Request
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'endorsmentReject'">
      Endorsement Reject the Request
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'REJECTED'">
      {{isAssignedPathologist?'You Reject the Case':'Pathologist Rejected the case'}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'SENT_BACK'">
      {{isAssignedPathologist?'You Sent the Case Back':'Pathologist Sent the Case Back'}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'PATH_SENT_BACK'">
      {{isAssignedPathologist?'You Sent the Case Back':'Pathologist Sent the Case Back'}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'REQUEST_REJECTED'">
      {{entityTypeEnum[dRequestType]}}
      Technician Not Accept the Case
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'TECHNICIAN_REJECT'">
      {{entityTypeEnum[dRequestType]}}
      Technician Not Accept the Case
    </span>
    <span class="badge-status move-F font_medium" *ngSwitchCase="'REPORTED'">
      {{isAssignedPathologist?'You Submitted the Report':'Pathologist Submitted Report'}}
    </span>
    <span class="badge-status white font_medium" *ngSwitchCase="'DRAFTED'">
      {{isAssignedPathologist?'Report Saved as a Draft':'Pathologist Saved as a Draft Report'}}
    </span>
    <span class="badge-status blue font_medium" *ngSwitchCase="'REQUESTED_SECOND_OPINION'">
      {{isAssignedPathologist?'You Asked for a Second Opinion':'Pathologist Asked for a Second Opinion'}}
    </span>
    <span class="badge-status green font_medium" *ngSwitchCase="'SUBMIT_COMMENTS'">
      {{
      isAssignedPathologist && pathologistSource === 'SECOND_OPNION'?
      'You Submitted Comments':'S.O. Pathologist Submitted Comments'
      }}
    </span>
    <span class="badge-status primary-2 font_medium" *ngSwitchCase="'ENDORSEMENT_AGREED'">
      {{
      isAssignedPathologist && pathologistSource === 'ENDORSEMENT'?
      'You Submitted Feedback (Malignant)':'Endorsement Pathologist Submitted Feedback'
      }}
    </span>
    <span class="badge-status primary-2 font_medium" *ngSwitchCase="'ENDORSEMENT_NOT_AGREED'">
      {{
      isAssignedPathologist && pathologistSource === 'ENDORSEMENT'?
      'You Submitted Feedback (Not Malignant)':'Endorsement Pathologist Submitted Feedback'
      }}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'SENT_BACK_SECOND_OPINION'">
      {{
      isAssignedPathologist && pathologistSource === 'SECOND_OPNION'?
      'Pathologist Sent the Case Back':'Pathologist Sent the Case Back'
      }}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'UPDATES_SUBMITTED'">
      {{
      isAssignedPathologist?
      'Technician uploaded slides':
      'Technician uploaded slides'
      }}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'REJECTED_PASS_PATHOLOGISTS'">
      {{
      isAssignedPathologist?
      'Supervisor Sent the Case Back':
      'Supervisor Sent the Case Back'
      }}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'SUPERVISOR_APPROVED_SLIDES'">
      {{
      isAssignedPathologist?
      'Case Updated as per Your Request':
      'Case is approved'
      }}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'SEND_BACK_UNDER_PROCESS'">
      {{
      isAssignedPathologist?
      'Send Back Under Process':
      'Send Back Under Process'
      }}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'PATH_SEND_BACK_UNDER_PROCESS'">
      {{
      isAssignedPathologist?
      'Send Back Under Process':
      'Send Back Under Process'
      }}
    </span>
    <span class="badge-status purple font_medium" *ngSwitchCase="'SENT_BACK_DONE'">
      Case Updated
    </span>
    <span class="badge-status green-2 font_medium" *ngSwitchCase="'REVIEWED'">
      {{
      isForQuality? 'Microscope diagnosis & report submitted':
      isAssignedPathologist? 'The report has been reviewed':
      isReviewer? 'The report has been reviewed':
      'The report has been reviewed'
      }}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'DECLINED'">
      {{
      isAssignedPathologist?
      'Reviewer Rejected the Report':
      'Reviewer Rejected the Report'
      }}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'CASE_REVIEWED_SUCCESFULLY'">
      Case Completed
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'CANCELLED'">
      {{pathologistSource === 'SECOND_OPNION'?'Primary Pathologist Cancelled the request':''}}
    </span>
    <span class="badge-status move-F font_medium" *ngSwitchCase="'WAITING_ENDORSEMENT_FEEDBACK'">
      {{isAssignedPathologist?'You Submitted the Report':'Pathologist Submitted the Report'}}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'ASSIGN_ENDORSEMENT'">
      {{isAssignedPathologist?'Report Saved as a Draft':'Pathologist Saved as a Draft Report'}}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'ACCEPTED_PROCESS_ONLY'">
      {{entityTypeEnum[dRequestType]}} Technician Accepted the Case
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'TECH_REF_SUPER_SENT_BACK_FROM_OTHER'">
      Case Sent Back from {{entityTypeEnum[dRequestType]}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'SUPER_SENT_BACK_FROM_OTHER'">
      Case Sent Back from {{entityTypeEnum[dRequestType]}}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'TECH_APP_SUPER_SENT_BACK'">
      Case Updated / Sent to {{entityTypeEnum[dRequestType]}}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'ACTION_TAKEN_TECH'">
      Case Updated / Sent to {{entityTypeEnum[dRequestType]}}
    </span>
    <span class="badge-status success font_medium" *ngSwitchCase="'CASE_HAS_APPROVED_REQUEST'">
      {{entityTypeEnum[dRequestType]}} Technician Accepted the Case
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'GROSSING'">
      Pathologist Assigned for Grossing
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'GROSSING_RESET'">
      Grossing Canceled
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'ASSINGED_TECHNOLOGIST'">
      {{hasPermission('LabTechnologist') || hasPermission('VLabTechnologist')?'New Case Assigned':'Case Submitted'}}
    </span>
    <span class="badge-status success font_medium" *ngSwitchCase="'GROSSING_COMPLETED'">
      {{
      hasPermission('LabTechnologist') || hasPermission('VLabTechnologist')?
      'Case Submitted':'Grossing Process Done / Slides Ready for Uploading'
      }}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'REJECT_GROSSING'">
      {{isAssignedPathologist?'You Reject the Case':'Pathologist for grossing Rejected the case'}}
    </span>
    <span class="badge-status active font_medium" *ngSwitchCase="'ACCEPT_GROSSING'">
      {{isAssignedPathologist?'You Accept the Case':'Pathologist for grossing Accepted the case'}}
    </span>
    <span class="badge-status active font_medium" *ngSwitchCase="'SUBMITED_GROSSING'">
      {{isAssignedPathologist?'You Submitted the Case':'Pathologist for grossing Submitted the case'}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'TECHNOLOGIST_SENT_BACK_GROSSING'">
      {{hasPermission('LabTechnologist,VLabTechnologist')?'You Sent the Case Back':'Technologist Sent the Case Back'}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'TECHNOLOGIST_SENT_BACK_GROSSING_UNDER_PROCESS'">
      Send Back Under Process
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'TECHNOLOGIST_SENT_BACK_GROSSING_DONE'">
      {{
      isAssignedPathologist?'You Updated the Case after sent back':
      'Pathologist for grossing Updated the Case after sent back'}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'SENT_BACK_GROSSING'">
      {{isAssignedPathologist?'You Sent the Case Back':'Pathologist for grossing Sent the Case Back'}}
    </span>
    <span class="badge-status active font_medium" *ngSwitchCase="'SUBMIT_SLIDE_REF'">
      Case Submitted
    </span>
    <span class="badge-status purple font_medium" *ngSwitchCase="'SENT_BACK_GROSSING_DONE'">
      Case Updated
    </span>
    <span class="badge-status active font_medium" *ngSwitchCase="'VERIFICATION_DONE'">
      {{isAssignedPathologist?'Diagnosis Verification Submitted':'Diagnosis Verification Submitted'}}
    </span>
    <span class="badge-status active font_medium" *ngSwitchCase="'VERIFICATION_SUBMITED'">
      {{isAssignedPathologist?'Diagnosis Verification Submitted':'Diagnosis Verification Submitted'}}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'PENDING'">
      {{isAssignedPathologist?'Sent to Confirm your Diagnosis':'Sent for Diagnosis Verification'}}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'VERIFICATION_ACCEPTED'">
      {{isAssignedPathologist?'Sent to Confirm your Diagnosis':'Sent for Diagnosis Verification'}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'VERIFICATION_WITH_DRAW'">
      Diagnosis Verification Withdrawal
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'VERIFICATION_REJECTED'">
      Diagnosis Verification Rejected
    </span>
    <span class="badge-status white font_medium" *ngSwitchDefault>
      Case Saved as a Draft
    </span>
  </span>

</ng-container>

<ng-container *ngIf="typeCase === 'RefCase' && !DsendBack">
  <span [ngSwitch]="currentStatus">
    <span class="badge-status primary font_medium" *ngSwitchCase="'CASE_WITH_REQUEST'">
      Case Sent to {{entityTypeEnum[dRequestType]}}
      <!-- {{currentDRequet?'Case Sent to '+ entityTypeEnum[dRequestType]:'New Case Referred'}} -->
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'UPLOAD_SLIDES'">
      <!-- {{hasPermission('VLTechnician')?'You ':'Technician '}} --> Uploaded Slide(s)
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'CASE_HAS_REJECTED_REQUEST'">
      {{entityTypeEnum[dRequestType]}}
      Technician Not Accept the Case
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'CASE_HAS_APPROVED_BY_VIRTUAL_LAB'">
      Uploaded Slide(s)
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'CASE_HAS_APPROVED_BY_SCANNING_CENTER'">
      Uploaded Slide(s)
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'WAITING_VL_SUPERVISOR_PROCESS'">
      {{
      dRequestStatus === 'SUBMITTED' && !hasPermission('ScanningCenterTechnician') &&
      !hasPermission('ScanningCenterAdmin')?
      'Technician Updated the Case':
      dRequestType === 'VIRTUAL_LAB'?
      dRequestProcedure === 'PROCESS_ONLY'?
      'New Case Referred':'VL Technician'
      :'Scanning Center '
      }}
      {{
      dRequestProcedure === 'PROCESS_ONLY'? '':
      dRequestStatus === 'REUPLOADED_SLIDES'?
      'Reuploaded Slide(s)':'Uploaded Slide(s)'
      }}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'CASE_REUPLOADED_SLIDES'">
      {{
      dRequestType === 'VIRTUAL_LAB'?
      hasPermission('VLTechnician')?'You ':'VL Technician ':
      hasPermission('ScanningCenterTechnician')?'You ':'Scanning Center Technician '}}
      Reuploaded Slide(s)
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'SUPERVISOR_REJECT_UPLOADED_SLIDES'">
      {{
      dRequestProcedure === 'SCANNING_PROCESS' || dRequestProcedure === 'PROCESS_ONLY'?
      hasPermission('VLSupervisor') ? 'You ':'VL Supervisor ':'Supervisor '
      }}
      {{dRequestProcedure === 'PROCESS_ONLY'?'Sent the Case Back':'Declined the Uploaded Slide(s)'}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'SUPERVISOR_REJECT_CASE_DETAILS'">
      {{
      hasPermission('LabSupervisor') || hasPermission('VLSupervisor')?
      'You Declined the Case Details':'Supervisor Sent the Case Back'
      }}
    </span>
    <span class="badge-status success font_medium"
      [ngClass]="{'primary': hasPermission('MatchMaker') || hasPermission('VLMatchMaker')}"
      *ngSwitchCase="'LAB_SUERVISOR_REVIEWED'">
      {{
      hasPermission('MatchMaker') || hasPermission('VLMatchMaker')?
      'New Case Submitted':
      dRequestProcedure === 'PROCESS_ONLY' || dRequestProcedure === 'SCANNING_PROCESS'?
      'VL Supervisor Approved the Case':
      'Supervisor Approved the Case'
      }}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'TECHNICIAN_REFUSED_SUPERVISOR_COMMENTS'">
      {{entityTypeEnum[dRequestType]}}
      Technician Not Accept the Case
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'MATCH_MAKING'">
      Assigned Pathologist
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'MATCHMAKING_RESET'">
      New Case Submitted
    </span>
    <span class="badge-status primary-2 font_medium" *ngSwitchCase="'ACTIVE'"
      [ngClass]="{'move-F': selectdEndorsement && (currentStatus === 'ACTIVE' || currentStatus === 'ACCEPTED')}">
      {{
      selectdSecondOpinion && (selectdSecondOpinion.status === 'ACTIVE' || selectdSecondOpinion.status === 'ACCEPTED' ||
      selectdSecondOpinion.status === 'SENT_BACK_SECOND_OPINION')?
      'You Asked for a Second Opinion':
      selectdEndorsement && (currentStatus === 'ACTIVE' || currentStatus === 'ACCEPTED')?
      'You Submitted the Report':

      isAssignedPathologist?
      pathologistSource === 'PRIMARY'? 'New Case Assigned':
      pathologistSource === 'ENDORSEMENT'?'New Case Assigned for Endorsement':
      pathologistSource === 'GROSSING' && isAssignedPathologist? 'New Case Assigned':
      'New Case Assigned for Second Opinion':
      pathologistSource === 'GROSSING' && !isAssignedPathologist? 'Pathologist Assigned for Grossing':
      hasPermission('LabTechnologist') || hasPermission('VLabTechnologist')?'New Case Assigned':
      'Assigned Pathologist'
      }}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'ACCEPTED'"
      [ngClass]="{'active': !selectdSecondOpinion && !selectdEndorsement && !isAssignedPathologist && nextStatus != 'WAITING_FOR_APPROVE'}">
      {{
      selectdSecondOpinion?'Second pathologist approve the case':
      selectdEndorsement?'Endorsement approve the case':
      isAssignedPathologist?'You Accept the Case':
      nextStatus === 'WAITING_FOR_APPROVE'?'New Case Referred':'Pathologist Accepted th case'}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'CLOSED'">
      Closed the Case
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'CLOSED_LAB_DELETED'">
      Closed the Case
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'REOPENED'">
      Reopened the Case
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'WITH_DRAW'">
      {{isAssignedPathologist?'You Withdrew from the Case':'Pathologist Withdrew from the Case'}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'secondOpnionWithdrow'">
      Second Opinion Withdrawal from the request"
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'endorsmentWithdrow'">
      Endorsement Withdrawal from the request"
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'secondOpnionReject'">
      Second Opinion Reject the Request
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'endorsmentReject'">
      Endorsement Reject the Request
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'REJECTED'">
      {{isAssignedPathologist?'You Reject the Case':'Pathologist Rejected the case'}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'SENT_BACK'">
      {{isAssignedPathologist?'You Sent the Case Back':'Pathologist Sent the Case Back'}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'PATH_SENT_BACK'">
      {{isAssignedPathologist?'You Sent the Case Back':'Pathologist Sent the Case Back'}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'REQUEST_REJECTED'">
      {{entityTypeEnum[dRequestType]}}
      Technician Not Accept the Case
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'TECHNICIAN_REJECT'">
      {{entityTypeEnum[dRequestType]}}
      Technician Not Accept the Case
    </span>
    <span class="badge-status move-F font_medium" *ngSwitchCase="'REPORTED'">
      {{isAssignedPathologist?'You Submitted the Report':'Pathologist Submitted Report'}}
    </span>
    <span class="badge-status white font_medium" *ngSwitchCase="'DRAFTED'">
      {{isAssignedPathologist?'Report Saved as a Draft':'Pathologist Saved as a Draft Report'}}
    </span>
    <span class="badge-status blue font_medium" *ngSwitchCase="'REQUESTED_SECOND_OPINION'">
      {{isAssignedPathologist?'You Asked for a Second Opinion':'Pathologist Asked for a Second Opinion'}}
    </span>
    <span class="badge-status green font_medium" *ngSwitchCase="'SUBMIT_COMMENTS'">
      {{
      isAssignedPathologist && pathologistSource === 'SECOND_OPNION'?
      'You Submitted Comments':'S.O. Pathologist Submitted Comments'
      }}
    </span>
    <span class="badge-status primary-2 font_medium" *ngSwitchCase="'ENDORSEMENT_AGREED'">
      {{
      isAssignedPathologist && pathologistSource === 'ENDORSEMENT'?
      'You Submitted Feedback (Malignant)':'Endorsement Pathologist Submitted Feedback'
      }}
    </span>
    <span class="badge-status primary-2 font_medium" *ngSwitchCase="'ENDORSEMENT_NOT_AGREED'">
      {{
      isAssignedPathologist && pathologistSource === 'ENDORSEMENT'?
      'You Submitted Feedback (Not Malignant)':'Endorsement Pathologist Submitted Feedback'
      }}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'SENT_BACK_SECOND_OPINION'">
      {{
      isAssignedPathologist && pathologistSource === 'SECOND_OPNION'?
      'Pathologist Sent the Case Back':'Pathologist Sent the Case Back'
      }}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'UPDATES_SUBMITTED'">
      {{
      isAssignedPathologist?
      'Technician uploaded slides':
      'Technician uploaded slides'
      }}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'REJECTED_PASS_PATHOLOGISTS'">
      {{
      isAssignedPathologist?
      'Supervisor Sent the Case Back':
      'Supervisor Sent the Case Back'
      }}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'SUPERVISOR_APPROVED_SLIDES'">
      {{
      isAssignedPathologist?
      'Case Updated as per Your Request':
      'Case is approved'
      }}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'SEND_BACK_UNDER_PROCESS'">
      {{
      isAssignedPathologist?
      'Send Back Under Process':
      'Send Back Under Process'
      }}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'PATH_SEND_BACK_UNDER_PROCESS'">
      {{
      isAssignedPathologist?
      'Send Back Under Process':
      'Send Back Under Process'
      }}
    </span>
    <span class="badge-status purple font_medium" *ngSwitchCase="'SENT_BACK_DONE'">
      Case Updated
    </span>
    <span class="badge-status green-2 font_medium" *ngSwitchCase="'REVIEWED'">
      {{
      isForQuality? 'Microscope diagnosis & report submitted':
      isAssignedPathologist? 'The report has been reviewed':
      isReviewer? 'The report has been reviewed':
      'The report has been reviewed'
      }}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'DECLINED'">
      {{
      isAssignedPathologist?
      'Reviewer Rejected the Report':
      'Reviewer Rejected the Report'
      }}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'CASE_REVIEWED_SUCCESFULLY'">
      Case Completed
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'IDLE'">
      New Case Referred
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'CANCELLED'">
      {{pathologistSource === 'SECOND_OPNION'?'Primary Pathologist Cancelled the request':''}}
    </span>
    <span class="badge-status move-F font_medium" *ngSwitchCase="'WAITING_ENDORSEMENT_FEEDBACK'">
      {{isAssignedPathologist?'You Submitted the Report':'Pathologist Submitted the Report'}}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'ASSIGN_ENDORSEMENT'">
      {{isAssignedPathologist?'Report Saved as a Draft':'Pathologist Saved as a Draft Report'}}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'CASE_SUBMITTED'">
      Case Submitted
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'ACCEPTED_PROCESS_ONLY'">
      {{entityTypeEnum[dRequestType]}} Technician Accepted the Case
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'SUPER_SENT_BACK'">
      {{
      hasPermission('LabSupervisor') || hasPermission('VLSupervisor')?
      'You Declined the Case Details':'Supervisor Sent the Case Back'
      }}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'ACTION_TAKEN'">
      {{
      hasPermission('LabSupervisor') || hasPermission('VLSupervisor')?
      'You Declined the Case Details':'Supervisor Sent the Case Back'
      }}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'TECH_REF_SUPER_SENT_BACK'">
      Case Sent Back to the {{entityTypeEnum[requestedType]}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'ACTION_TAKEN_TECH_REFUSE'">
      Case Sent Back to the {{entityTypeEnum[requestedType]}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'SUPER_SENT_BACK_FROM_OTHER'">
      Case Sent Back from {{entityTypeEnum[dRequestType]}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'TECH_REF_SUPER_SENT_BACK_FROM_OTHER'">
      Case Sent Back from {{entityTypeEnum[dRequestType]}}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'TECH_APP_SUPER_SENT_BACK_FROM_OTHER'">
      Case Updated
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'TECH_APP_SUPER_SENT_BACK'">
      Case Updated / Sent to {{entityTypeEnum[dRequestType]}}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'ACTION_TAKEN_TECH'">
      Case Updated / Sent to {{entityTypeEnum[dRequestType]}}
    </span>
    <span class="badge-status success font_medium"
      [ngClass]="{'primary': hasPermission('MatchMaker') || hasPermission('VLMatchMaker')}"
      *ngSwitchCase="'LAB_SUPERVISOR_APPROVED'">
      {{
      hasPermission('LabSupervisor') || hasPermission('VLSupervisor')?
      hasPermission('MatchMaker') || hasPermission('VLMatchMaker')?
      'You Approved the Case':
      hasPermission('VLSupervisor') ? 'VL Supervisor Approved the Case':' Supervisor Approved the Case' :
      hasPermission('MatchMaker') || hasPermission('VLMatchMaker')?
      'New Case Submitted':'Supervisor Approved the Case'
      }}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'VIRTUAL_LAB_UPLOADED_SLIDES'">
      VL Technician Uploaded Slide(s)
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'SCANNING_CENTER_UPLOADED_SLIDES'">
      Scanning Center Technician Uploaded Slide(s)
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'GROSSING'">
      Pathologist Assigned for Grossing
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'GROSSING_RESET'">
      Grossing Canceled
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'ASSINGED_TECHNOLOGIST'">
      {{hasPermission('LabTechnologist') || hasPermission('VLabTechnologist')?'New Case Assigned':'Case Submitted'}}
    </span>
    <span class="badge-status success font_medium" *ngSwitchCase="'GROSSING_COMPLETED'">
      {{
      hasPermission('LabTechnologist') || hasPermission('VLabTechnologist')?
      'Case Submitted':'Grossing Process Done / Slides Ready for Uploading'
      }}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'REJECT_GROSSING'">
      {{isAssignedPathologist?'You Reject the Case':'Pathologist for grossing Rejected the case'}}
    </span>
    <span class="badge-status active font_medium" *ngSwitchCase="'ACCEPT_GROSSING'">
      {{isAssignedPathologist?'You Accept the Case':'Pathologist for grossing Accepted the case'}}
    </span>
    <span class="badge-status active font_medium" *ngSwitchCase="'SUBMITED_GROSSING'">
      {{isAssignedPathologist?'You Submitted the Case':'Pathologist for grossing Submitted the case'}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'TECHNOLOGIST_SENT_BACK_GROSSING'">
      {{hasPermission('LabTechnologist,VLabTechnologist')?'You Sent the Case Back':'Technologist Sent the Case Back'}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'TECHNOLOGIST_SENT_BACK_GROSSING_UNDER_PROCESS'">
      Send Back Under Process
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'TECHNOLOGIST_SENT_BACK_GROSSING_DONE'">
      {{
      isAssignedPathologist?
      'You Updated the Case after sent back':
      'Pathologist for grossing Updated the Case after sent back'
      }}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'SENT_BACK_GROSSING'">
      {{isAssignedPathologist?'You Sent the Case Back':'Pathologist for grossing Sent the Case Back'}}
    </span>
    <span class="badge-status active font_medium" *ngSwitchCase="'SUBMIT_SLIDE_REF'">
      Case Submitted
    </span>
    <span class="badge-status purple font_medium" *ngSwitchCase="'SENT_BACK_GROSSING_DONE'">
      Case Updated
    </span>
    <span class="badge-status active font_medium" *ngSwitchCase="'VERIFICATION_DONE'">
      {{isAssignedPathologist?'Diagnosis Verification Submitted':'Diagnosis Verification Submitted'}}
    </span>
    <span class="badge-status active font_medium" *ngSwitchCase="'VERIFICATION_SUBMITED'">
      {{isAssignedPathologist?'Diagnosis Verification Submitted':'Diagnosis Verification Submitted'}}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'PENDING'">
      {{isAssignedPathologist?'Sent to Confirm your Diagnosis':'Sent for Diagnosis Verification'}}
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'VERIFICATION_ACCEPTED'">
      {{isAssignedPathologist?'Sent to Confirm your Diagnosis':'Sent for Diagnosis Verification'}}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'VERIFICATION_WITH_DRAW'">
      Diagnosis Verification Withdrawal
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'VERIFICATION_REJECTED'">
      Diagnosis Verification Rejected
    </span>
    <span class="badge-status primary font_medium" *ngSwitchDefault>
      New Case Referred
    </span>
  </span>

</ng-container>

<ng-container *ngIf="typeCase === 'RefCase' && DsendBack">
  <span [ngSwitch]="currentStatus">
    <span class="badge-status primary font_medium" *ngSwitchCase="'IDLE'">
      New Case Referred
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'TECHNICIAN_SUBMIT_UPDATES'">
      Uploaded Slide(s)
    </span>
    <span class="badge-status primary font_medium" *ngSwitchCase="'SUPERVISOR_APPROVED_SLIDES'">
      {{
      isAssignedPathologist?
      'Case Updated as per Your Request':
      dRequestType === 'VIRTUAL_LAB'?'VL technician Uploaded slides ':
      dRequestType === 'SCANNING_CENTER'?'Scanning Center technician Uploaded slides ':
      'Case is approved'
      }}
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'REJECTED'">
      {{entityTypeEnum[dRequestType]}}
      Technician Not Accept the Case
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'REJECTED_PASS_PATHOLOGISTS'">
      Supervisor Sent the Case Back
    </span>
    <span class="badge-status danger font_medium" *ngSwitchCase="'TECHNICIAN_REJECT'">
      {{entityTypeEnum[dRequestType]}}
      Technician Not Accept the Case
    </span>
    <span class="badge-status primary font_medium" *ngSwitchDefault>
      New Case Referred
    </span>
  </span>

</ng-container>