import { CaseType } from 'src/app/models/cases/enums/CaseType';
import { GlobalFilter } from '../global/GlobalFilter';
export class ListFilter extends GlobalFilter {
  CaseId?: number;
  UserId?: number;
  UserType?: string;
  SentBackRequestId?: number;
  caseIdType: CaseType;
  IsMulti: boolean;
  dRequestId: number;
}
