import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-concurel-account-msg',
  templateUrl: './concurel-account-msg.component.html'
})
export class ConcurelAccountMsgComponent implements OnInit {
  @Input() notAuthorized = false;
  @Input() successMsg = false;
  @Output() closeDailog = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    console.log(this.successMsg);

  }

  close() {
    this.closeDailog.emit(true);
  }


}
