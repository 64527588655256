import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SlidesService {
  refreshSlidesList = new BehaviorSubject<boolean>(false);
  openForm = new BehaviorSubject(null);

  constructor(private http: HttpClient) {
  }

  getAll(params, notAuthenticated = false): any {
    if (notAuthenticated) {
      return this.http.get(`${environment.apiUrl}/Case/ListCaseSlidesAnn`, { params });
    } else {
      return this.http.get(`${environment.apiUrl}/Case/ListCaseSlides`, { params });
    }
  }
  getOtherSlidesLookup(params): any {
    return this.http.get(`${environment.apiUrl}/Case/GetOtherSlidesLookup`, { params });
  }
  getDetails(id) {
    return this.http.get(`${environment.apiUrl}/slides/GetSlideDetails?SlideId=${id}`);
  }
  saveRecord(data, slideID = null) {
    if (slideID) {
      return this.http.put(`${environment.apiUrl}/Case/UpdateSlide`, data, {
        reportProgress: true,
        observe: 'events'
      });
    } else {
      return this.http.post(`${environment.apiUrl}/Case/AddSlide`, data, {
        reportProgress: true,
        observe: 'events'
      });
    }
  }

  addOtherSlide(data) {
    return this.http.put(`${environment.apiUrl}/Case/AddOtherSlide`, data);
  }

  deleteRecord(params) {
    return this.http.delete(`${environment.apiUrl}/Case/DeleteSlide`, { params });
  }

  showHideSlide(data) {
    return this.http.put(`${environment.apiUrl}/Case/ShowHideSlide`, data);
  }

  addSlideReference(data): any {
    return this.http.post(`${environment.apiUrl}/Case/AddSlideReference`, data);
  }

  deleteSlideReference(params: any) {
    return this.http.delete(`${environment.apiUrl}/Case/DeleteSlideReference`, { params });
  }

  readReferenceSlides(data): any {
    return this.http.post(`${environment.apiUrl}/SlidesReference/ReadReferenceSlides`, data);
  }

  updateContainerReference(data): any {
    return this.http.post(`${environment.apiUrl}/CaseContainer/UpdateContainerReference`, data);
  }

  updateSlideReference(data): any {
    return this.http.post(`${environment.apiUrl}/SlidesReference/UpdateSlideReference`, data);
  }
  IsSlideViewed(id) {
    return this.http.put(`${environment.apiUrl}/Case/IsSlideViewed?SlideId=${id}` , {});
  }
}
