// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { CaseType } from "src/app/models/cases/enums/CaseType";

export const environment = {
  production: false,
  // appUrl: 'https://histocr.expertapps.com.sa', // CR URL
  // apiUrl: '/api', // CR API front
  // signalR: 'https://histocrslr.expertapps.com.sa/hubs', // CR SignalR

  // appUrl: 'http://telepathology.neom.com', // telepathology URL
  // apiUrl: 'http://telepathologyapi.neom.com/api', // telepathology API front
  // signalR: 'http://telepathologyapi.neom.com/hubs', // telepathology SignalR

  // appUrl: 'https://histocr.expertapps.com.sa', // CR URL
  // apiUrl: 'https://histocrapi.expertapps.com.sa/api', // CR API front
  // signalR: 'https://histocrslr.expertapps.com.sa/hubs', // CR SignalR

  // appUrl: 'https://histoaienhanced.expertapps.com.sa', // CR URL
  // apiUrl: 'https://histoaienhancedapi.expertapps.com.sa/api', // CR API front
  // signalR: 'https://histoaienhancedslr.expertapps.com.sa/hubs', // CR SignalR

  // appUrl: 'https://histospecialstainapi.expertapps.com.sa', // CR URL
  // apiUrl: 'https://histospecialstainapi.expertapps.com.sa/api', // CR API front
  // signalR: 'https://histospecialstainapi.expertapps.com.sa/hubs', // CR SignalR

  // appUrl: 'https://histoai.expertapps.com.sa', // AI URL
  // apiUrl: 'https://histoaiapi.expertapps.com.sa/api', // AI API front
  // signalR: 'https://histocrslr.expertapps.com.sa/hubs', // AI SignalR

  // appUrl: 'https://histodev.expertapps.com.sa', // DEV URL
  // apiUrl: 'https://histodevapi.expertapps.com.sa/api', // DEV API front
  // signalR: 'https://histodevslr.expertapps.com.sa/hubs', // DEV SignalR

  // appUrl: 'https://histocrtest.expertapps.com.sa/', // CR TEST URL
  // apiUrl: 'https://histocrtestapi.expertapps.com.sa/api', // CR TEST API front
  // signalR: 'https://histocrtestslr.expertapps.com.sa/hubs', // CR TEST SignalR

  // appUrl: 'https://histoaitest.expertapps.com.sa/', // AI TEST URL
  // apiUrl: 'https://histoaiapitest.expertapps.com.sa/api', // AI TEST API front
  // signalR: 'https://histocrtestslr.expertapps.com.sa/hubs', // AI TEST SignalR

  // appUrl: 'https://histotest.expertapps.com.sa/', // TEST URL
  // apiUrl: 'https://histotestapi.expertapps.com.sa/api', // TEST API front
  // signalR: 'https://histotestslr.expertapps.com.sa/hubs', // TEST SignalR

  // appUrl: 'https://histostage.expertapps.com.sa/', // Stage URL
  // apiUrl: 'https://histostageapi.expertapps.com.sa/api', // Stage API front
  // signalR: 'https://histostageslr.expertapps.com.sa/hubs', // Stage SignalR

  // appUrl: 'https://histodemo.expertapps.com.sa/', // Demo URL
  // apiUrl: 'https://histodemoapi.expertapps.com.sa/api', // Demo API front
  // signalR: 'https://histodemoslr.expertapps.com.sa/hubs', // Demo SignalR

  // appUrl: 'https://histoapp.expertapps.com.sa/', // App URL
  // apiUrl: 'https://histoappapi.expertapps.com.sa/api', // App API front
  // signalR: 'https://histoappslr.expertapps.com.sa/hubs', // App SignalR

  // appUrl: 'https://histoapp.expertapps.com.sa/', // App URL
  // apiUrl: 'https://histo-appapi.expertapps.com.sa/api', // App API front
  // signalR: 'https://histo-appslr.expertapps.com.sa/hubs', // App SignalR


  // appUrl: 'https://histoapp.expertapps.com.sa/', // IP URL
  // apiUrl: 'http://192.168.2.108/api', // IP API front
  // signalR: 'https://histoappslr.expertapps.com.sa/hubs', // IP SignalR

  // appUrl: 'https://histotest.expertapps.com.sa/', // Production URL
  // apiUrl: 'https://histoapi.histo-app.com/api', // Production API front
  // signalR: 'https://histonotification.histo-app.com/hubs', // Production SignalR

  // appUrl: 'https://histopapi.histo-app.com/', // Demo URL
  // apiUrl: 'https://histopapi.histo-app.com/api', // Demo API front
  // signalR: 'https://histonotifications.histo-app.com/hubs', // Demo SignalR

  appUrl: 'https://histopapi.histo-app.com/', // Demo URL
  apiUrl: 'https://histo.histo-app.com/HistoApi/api', // Demo API front
  signalR: 'https://histo.histo-app.com/slr/hubs', // Demo SignalR

  // appUrl: 'https://histopapi.histo-app.com/', // connect URL
  // apiUrl: 'https://connectapi.expertapps.com.sa/api', // connect API front
  // signalR: 'https://connectslr.expertapps.com.sa/hubs', // connect SignalR

  // appUrl: 'https://linfe.histo-app.com/', // linux URL
  // apiUrl: 'https://linapi.histo-app.com/api', // linux API front
  // signalR: 'https://linslr.histo-app.com/hubs', // linux SignalR

  // appUrl: 'https://histoapi.histo-app.com/', // HISTO APP URL
  // apiUrl: 'https://histoapi.histo-app.com/api', // HISTO APP API front
  // signalR: 'https://histoslr.histo-app.com/hubs', // HISTO APP SignalR
  sharedCaseId: 691,
  sharedCaseIdType: CaseType.Case,

  appZoomUrl: 'https://histo.expertapps.com.sa/',
  apiZoomUrl: 'https://histoapi.expertapps.com.sa/api',

  googleApiKey: 'AIzaSyBdScej8zNyAjz4YQuoWeo0jTfYv56pFc4',
  googleSiteKey: '6LeIGsocAAAAAAgHB8An7QMb99naC8cdTXL0qi-t',
  deepgramKey: 'd87da8e8561cbbeba35e4d69d495c0dbeb52af94'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
