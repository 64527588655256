<span [ngSwitch]="status">
  <span class="badge-status orange" *ngSwitchCase="slaStatus.PENDING">
    Waiting Acceptance
  </span>
  <span class="badge-status active" *ngSwitchCase="slaStatus.APPROVED">
    Active
  </span>
  <span class="badge-status danger" *ngSwitchCase="slaStatus.REJECTED">
    Declined
  </span>
  <span class="badge-status danger" *ngSwitchCase="slaStatus.EXPIRED">
    Expired
  </span>
  <span class="badge-status danger" *ngSwitchCase="slaStatus.TERMINATED">
    Terminated
  </span>
  <span class="badge-status danger" *ngSwitchCase="slaStatus.DECLINED">
    Declined
  </span>
  <span class="badge-status white" *ngSwitchCase="slaStatus.NOT_ADDED">
    Not Added
  </span>
  <span class="badge-status danger" *ngSwitchCase="slaStatus.PAUSED">
    Paused
  </span>
  <span class="badge-status warning" *ngSwitchDefault>
    --
  </span>
</span>