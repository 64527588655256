import { Component, Input, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserFormComponent } from 'src/app/features/users/user-management/user-form/user-form.component';
import { ModelsEnum } from 'src/app/models/users/users/enums/ModelsEnum';
import { ListData } from 'src/app/models/users/shared-users-list/ListData';
import { ListFilter } from 'src/app/models/users/shared-users-list/ListFilter';
import { UsersService } from 'src/app/services/users.service';
import { SelectUserTypeComponent } from 'src/app/features/users/user-management/select-user-type/select-user-type.component';

@Component({
  selector: 'app-users-list-shared',
  templateUrl: './users-list-shared.component.html'
})
export class UsersListSharedComponent implements OnInit {
  @Input() entityId: number;
  @Input() entityType: ModelsEnum;
  @Output() refreshDetails = new EventEmitter<boolean>();
  @Input() sla: any;
  dataLoading = false;
  selectedRecord: any;
  openSideNav = false;
  module: ModelsEnum;
  // table
  pageIndex = 0;
  pageCount = 0;
  recordsData: ListData = new ListData();
  filterModel: ListFilter = new ListFilter();
  minNUmberPages = 0;
  maxNUmberPages = 5;
  visibleNUmberPages = 6;

  constructor(
    public dialog: MatDialog,
    private service: UsersService,
  ) { }

  ngOnInit(): void {
    this.service.refreshUsersList.subscribe(data => {
      if (data) {
        this.getAll(null);
      }
    });
    this.filterModel.PageSize = 5;
    this.module = this.entityType;
    this.getAll(null);
  }

  getAll(event) {
    this.dataLoading = true;
    if (event) {
      this.pageIndex = event;
      if (
        (this.pageCount - 1) > this.visibleNUmberPages &&
        (this.pageIndex + 3) >= this.visibleNUmberPages
      ) {
        this.minNUmberPages = this.pageIndex === 0 ? 0 : (Math.abs(this.pageCount - this.pageIndex) < this.visibleNUmberPages ? Math.abs(this.pageCount - this.visibleNUmberPages) : this.pageIndex - 1);
        this.maxNUmberPages = ((this.pageCount - 1) == this.pageIndex) ? this.minNUmberPages + this.visibleNUmberPages : (this.minNUmberPages + this.visibleNUmberPages) - 1;
      } else {
        this.minNUmberPages = 0;
        this.maxNUmberPages = 5;
      }
    } else {
      console.log('dsadsa')
      this.pageIndex = 0;
      this.minNUmberPages = 0;
      this.maxNUmberPages = 5;
    }
    console.log(this.minNUmberPages, this.maxNUmberPages)
    this.filterModel.PageNumber = this.pageIndex + 1;

    if (this.entityType === ModelsEnum.Lab) {
      this.filterModel.LabId = this.entityId;
    } else if (this.entityType === ModelsEnum.ScanningCenter) {
      this.filterModel.ScanningCenterId = this.entityId;
    } else if (this.entityType === ModelsEnum.Hospital) {
      this.filterModel.EntityId = this.entityId;
      this.filterModel.EntityType = 'HOSPITAL';
    } else if (this.entityType === ModelsEnum.Clinic) {
      this.filterModel.EntityId = this.entityId;
      this.filterModel.EntityType = 'CLINIC';
    }

    this.service.getAllInProfiles(this.filterModel, this.entityType).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.recordsData = data as ListData;
          this.pageCount = Math.ceil(this.recordsData.usersCount / this.filterModel.PageSize);
        }
      },
      error => {
        this.dataLoading = false;
      });
  }

  counter(i: number) { return new Array(i); }

  openForm() {
    const dialogRef = this.dialog.open(UserFormComponent, {
      width: '620px',
      data: {
        id: null,
        record: null,
        userId: null,
        labId: this.entityId,
        scId: this.entityId,
        hospitalId: this.entityId,
        clinicId: this.entityId,
        model: this.entityType
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAll(null);
      }
    });
  }

  openPathologistForm() {
    const obj = {
      record: null,
      userId: null,
      labId: this.entityId,
      model: 'PATHOLOGIST_USER',
      isModuleAdmin: false,
      makeSearchForPathologist: true,
      pathologistLabType: 'LAB',
    };
    const dialogRef = this.dialog.open(UserFormComponent, {
      width: '620px',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAll(null);
      }
    });
  }

  openSelectUserType() {
    const dialogRef = this.dialog.open(SelectUserTypeComponent, {
      width: '550px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result === 1) {
          this.openForm();
        } else if (result === 2) {
          this.openPathologistForm();
        }
        // this.getAll(null);
      }
    });
  }

  checkEntityTypeToAddUser() {
    if (this.entityType === ModelsEnum.Lab) {
      this.openSelectUserType();
    } else {
      this.openForm();
    }
  }

  userDetails(record) {
    this.selectedRecord = record;
    this.openSideNav = true;
  }

  closeSidenav(e) {
    this.openSideNav = false;
  }

  checkRole(item: string) {
    if (this.selectedRecord.roles) {
      return this.selectedRecord.roles.includes(item);
    }
    return false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    console.log(event)
  }

  isMobile(size) {
    return window && window.matchMedia(`(max-width: ${size}px)`).matches;
  }

}
