import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
})
export class PaginationComponent implements OnInit {
  @Input() pageCount: number;
  @Input() total: number;
  @Input() pageSize: number;
  @Input() pageNumber: number;
  @Input() manualPage: number;
  @Output() updatePage = new EventEmitter<any>();
  @Output() getAll = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  updatePageEvent(e) {
    this.updatePage.emit(e);
  }
  getAllEvent(index) {
    this.getAll.emit(index);
  }

}
