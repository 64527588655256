import { Component, Input, OnInit } from '@angular/core';
import { CommunicationMethod } from 'src/app/models/global/CommunicationMethod';

@Component({
  selector: 'app-entity-communication-methods',
  templateUrl: './entity-communication-methods.component.html'
})
export class EntityCommunicationMethodsComponent implements OnInit {

  @Input() communicationMethodList: CommunicationMethod[] = [];
  @Input() entityName: string;
  showPassList: number[] = [];

  constructor() { }

  ngOnInit() {
  }

  isInArray(parentObj, id) {
    return parentObj.indexOf(id) > -1;
  }

  showPassword(i) {
    const index = this.showPassList.indexOf(i);
    if (index > -1) {
      this.showPassList.splice(index, 1);
    } else {
      this.showPassList.push(i);
    }
  }
}
